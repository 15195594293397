import React, {useCallback, useMemo} from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {useLocaleProvider} from "../context/localeContext";

export default function InlinksBanner() {
  /*const showYoutubeVideo = useCallback(() => {
    onYouTubePlayerAPIReady("TP3qL6aPMqk");
  }, []);*/

  const messages = defineMessages({
    title: {
      id: "page.banner.title",
      description: "banner title",
      defaultMessage: "¿Listo para empezar?",
    },
    text: {
      id: "page.banner.text",
      description: "banner text",
      defaultMessage:
        "¿Estás seguro de que tienes el mejor motor? Ponlo a prueba, te ofrecemos un test AB gratis durante 3 meses.*",
    },
    text2: {
      id: "page.banner.text2",
      description: "banner text",
      defaultMessage:
        "¿Estás seguro de que tienes el mejor motor? Ponlo a prueba, te ofrecemos un test AB gratis durante 3 meses.*",
    },
    btnOne: {
      id: "page.banner.btnOne",
      description: "button text",
      defaultMessage: "Solicitar demo",
    },
    btnTwo: {
      id: "page.banner.btnTwo",
      description: "button text",
      defaultMessage: "Contacta",
    },
    subText: {
      id: "page.banner.subText",
      description: "banner * text",
      defaultMessage:
        "*Válido para hoteles de más de 150 habitaciones o grupos hoteleros de más de 300 habitaciones",
    },
  });

  const localeProvider = useLocaleProvider();
  const changeLanguague = useCallback(
    (e, locale) => {
      e.preventDefault();
      const languagePopup = document.getElementById("languages");
      languagePopup.classList.remove("open");
      localeProvider.setLocale(locale);
    },
    [localeProvider]
  );

  const localeText = useMemo(
    () => (localeProvider.currentLocale === "en-US" ? "EN" : "ES"),
    [localeProvider]
  );

  const isSelectedEnglish = useMemo(
    () => localeProvider.currentLocale === "en-US",
    [localeProvider]
  );

  return (
    <>
      <div className={`inlinks-banner`}>
        <div className="big-wrapper">
          <div className="wrap-inlinks-banner">
            <p className="inlinks-banner-title">
              <FormattedMessage {...messages.title} />
            </p>
            <p className="inlinks-banner-text">
              <FormattedMessage {...messages.text} />
            </p>
            <p className="inlinks-banner-text">
              <FormattedMessage {...messages.text2} />
            </p>
            <div className="inlinks-banner-btns">
              <div className="banner-btns-element btn-corp">
                <a href={isSelectedEnglish ? "/contact" : "/contacto"} className="inlinks-btn">
                  <FormattedMessage {...messages.btnOne} />
                </a>
              </div>
            </div>
            <p className="inlinks-banner-text inlinks-banner-small-text">
              <small>
                <FormattedMessage {...messages.subText} />
              </small>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
