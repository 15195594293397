import React, {useMemo} from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {useLocaleProvider} from "../context/localeContext";
import Linkedin from "../assets/img/rrss/linkedin.svg"
import YouTube from "../assets/img/rrss/youtube.svg"
import Septeo from "../assets/img/logo_septeo.svg"
import X from "../assets/img/rrss/x.svg";
import Facebook from "../assets/img/rrss/facebook.svg";
import Instagram from "../assets/img/rrss/instagram.svg";

const messages = defineMessages({
  aboutUs: {
    id: "page.footer.aboutUs",
    description: "footer address title",
    defaultMessage: "¿Quiénes somos?",
  },
  visionAndValues: {
    id: "page.footer.visionAndValues",
    description: "footer vision and values",
    defaultMessage: "Visión y valores",
  },
  commitment: {
    id: "page.footer.commitment",
    description: "footer commitment",
    defaultMessage: "Compromiso",
  },
  ourTeam: {
    id: "page.footer.ourTeam",
    description: "footer our team",
    defaultMessage: "Nuestro equipo",
  },
  present: {
    id: "page.footer.present",
    description: "footer present",
    defaultMessage: "Actualidad",
  },
  address: {
    id: "page.footer.address",
    description: "footer address title",
    defaultMessage: "Dirección",
  },
  contact: {
    id: "page.footer.contact",
    description: "footer contact title",
    defaultMessage: "Contacto",
  },
  addressDataOne: {
    id: "page.footer.addressDataOne",
    description: "footer address data",
    defaultMessage: "Paseo Manuel Girona 16, Bajos",
  },
  addressDataTwo: {
    id: "page.footer.addressDataTwo",
    description: "footer address data",
    defaultMessage: "194 avenue de la Gare Sud de France",
  },
  addressDataThree: {
    id: "page.footer.addressDataThree",
    description: "footer address data",
    defaultMessage: "34970 Lattes",
  },
  phone: {
    id: "page.footer.phone",
    description: "footer phone title",
    defaultMessage: "Teléfono",
  },
  phoneNumber: {
    id: "page.footer.phoneNumber",
    description: "footer phone number",
    defaultMessage: "+34 921 920 300",
  },
  emailAddress: {
    id: "page.footer.emailAddress",
    description: "footer email address",
    defaultMessage: "witbooking@septeo.com",
  },
  emailExtra: {
    id: "page.footer.emailExtra",
    description: "footer email extra title",
    defaultMessage: "Delegación Baleares",
  },
  emailExtraAddress: {
    id: "page.footer.emailExtraAddress",
    description: "footer email extra address",
    defaultMessage: "baleares@witbooking.com",
  },
  rrss: {
    id: "page.footer.rrss",
    description: "footer rrss title",
    defaultMessage: "RRSS",
  },
  rrssLinkedin: {
    id: "page.footer.rrssLinkedin",
    description: "footer rrss Linkedin",
    defaultMessage: "Linked In",
  },
  rrssTwitter: {
    id: "page.footer.rrssTwitter",
    description: "footer rrss Twitter",
    defaultMessage: "Twitter",
  },
  webmap: {
    id: "page.footer.webmap",
    description: "footer webmap title",
    defaultMessage: "Mapa web",
  },
  webmapEngine: {
    id: "page.footer.webmapEngine",
    description: "footer webmap engine",
    defaultMessage: "Motor de reserves",
  },
  webmapModules: {
    id: "page.footer.webmapModules",
    description: "footer webmap modules",
    defaultMessage: "Módulos",
  },
  webmapIntegrations: {
    id: "page.footer.webmapIntegrations",
    description: "footer webmap integrations",
    defaultMessage: "Integraciones",
  },
  webmapPrices: {
    id: "page.footer.webmapPrices",
    description: "footer webmap prices",
    defaultMessage: "Precios",
  },
  webmapBlog: {
    id: "page.footer.webmapBlog",
    description: "footer webmap blog",
    defaultMessage: "Blog",
  },
  legal: {
    id: "page.footer.legal",
    description: "footer legal title",
    defaultMessage: "Legal",
  },
  legalAdvice: {
    id: "page.footer.legalAdvice",
    description: "footer legal advice",
    defaultMessage: "Aviso Legal",
  },
  legalPrivacity: {
    id: "page.footer.legalPrivacity",
    description: "footer legal privacity",
    defaultMessage: "Política de privacidad",
  },
  legalCookies: {
    id: "page.footer.legalCookies",
    description: "footer legal cookies",
    defaultMessage: "Política de cookies",
  },
  rights: {
    id: "page.footer.rights",
    description: "footer rights",
    defaultMessage: "All rights reserved",
  },
  addressDataTwoWit: {
    id: "page.footer.addressDataTwoWit",
    description: "footer address Wit",
    defaultMessage: "C/Numancia 46, 22º",
  },
  addressDataThreeWit: {
    id: "page.footer.addressDataThreeWit",
    description: "footer address Wit",
    defaultMessage: "08029 Barcelona",
  },
  phoneNumberWit: {
    id: "page.footer.phoneNumberWit",
    description: "footer phone Wit",
    defaultMessage: "Tlf. +34 931 998 294",
  },
  emailAddressWit: {
    id: "page.footer.emailAddressWit",
    description: "footer email Wit",
    defaultMessage: "Email: info@septeo.com",
  },
});

export default function Footer() {
  const localeProvider = useLocaleProvider();
  const isSelectedEnglish = useMemo(
    () => localeProvider.currentLocale === "en-US",
    [localeProvider]
  );

  return (
    <footer className="main-footer">
      <div className="big-wrapper">
        <div className="wrap-main-footer">
          <div className="main-footer-info">
            <div className="wrap-footer-info">
              <div className="wrap-footer-info-boxes">
                <div className="footer-info-box">
                  <div className="wrap-info-box">
                    <p className="info-box-title">
                      Septeo
                    </p>
                    <a
                      href="https://www.septeo.com/groupe/qui-sommes-nous"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.aboutUs} />
                    </a>
                    <a
                      href="https://www.septeo.com/groupe/vision-et-valeurs"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.visionAndValues} />
                    </a>
                    <a
                      href="https://www.septeo.com/groupe/engagements"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.commitment} />
                    </a>
                    <a
                      href="https://www.septeo.com/groupe/nous-rejoindre"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.ourTeam} />
                    </a>
                    <a
                      href="https://www.septeo.com/blog"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.present} />
                    </a>
                  </div>
                </div>
                <div className="footer-info-box">
                  <div className="wrap-info-box">
                    <p className="info-box-title">
                      <FormattedMessage {...messages.contact} />
                    </p>
                    <a
                      href="tel:+34932924800"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.phoneNumber} />
                    </a>
                    <a
                      href="mailto:contact@septeo.com"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.emailAddress} />
                    </a>
                    <a
                      href="https://maps.app.goo.gl/iJtXQRHmU9H4wtYdA"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                    <span>
                      <FormattedMessage {...messages.addressDataOne} />
                    </span>
                      <span>
                      <FormattedMessage {...messages.addressDataTwo} />
                    </span>
                      <span>
                      <FormattedMessage {...messages.addressDataThree} />
                    </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="wrap-footer-links">
                <a href="https://twitter.com/SEPTEO_GROUPE"
                   target="_blank"
                   rel="noreferrer"
                   className="footer-rrss">
                  <img
                    src={X}
                    className="rrss-logo"
                    alt="footer-x-logo"
                    loading="lazy"
                  />
                </a>
                <a href="https://www.youtube.com/channel/UCkEDgp2FLfuTyiwOO7jw7Wg"
                   target="_blank"
                   rel="noreferrer"
                   className="footer-rrss">
                  <img
                    src={YouTube}
                    className="rrss-logo"
                    alt="footer-youtube-logo"
                    loading="lazy"
                  />
                </a>
                <a href="https://www.linkedin.com/company/septeo/?trk=top_nav_home"
                   target="_blank"
                   rel="noreferrer"
                   className="footer-rrss">
                  <img
                    src={Linkedin}
                    className="rrss-logo"
                    alt="footer-linkedin-logo"
                    loading="lazy"
                  />
                </a>
                <a href="https://www.facebook.com/SEPTEOGROUPE"
                   target="_blank"
                   rel="noreferrer"
                   className="footer-rrss">
                  <img
                    src={Facebook}
                    className="rrss-logo"
                    alt="footer-facebook-logo"
                    loading="lazy"
                  />
                </a>
                <a href="https://www.instagram.com/septeo_groupe/"
                   target="_blank"
                   rel="noreferrer"
                   className="footer-rrss">
                  <img
                    src={Instagram}
                    className="rrss-logo"
                    alt="footer-instagram-logo"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
            <div className="wrap-footer-info">
              <div className="wrap-footer-info-boxes">
                <div className="footer-info-box">
                  <div className="wrap-info-box">
                    <p className="info-box-title">
                      <FormattedMessage {...messages.webmap} />
                    </p>
                    <a
                      href={
                        isSelectedEnglish
                          ? "/booking-engine"
                          : "/motor-de-reservas"
                      }
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.webmapEngine} />
                    </a>
                    <a
                      href={isSelectedEnglish ? "/add-on" : "/modulos"}
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.webmapModules} />
                    </a>
                    <a
                      href={
                        isSelectedEnglish ? "/integrations" : "/integraciones"
                      }
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.webmapIntegrations} />
                    </a>
                    <a
                      href={isSelectedEnglish ? "/rates" : "/precios"}
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.webmapPrices} />
                    </a>
                  </div>
                </div>
                <div className="footer-info-box">
                  <div className="wrap-info-box">
                    <p className="info-box-title">
                      <FormattedMessage {...messages.contact} />
                    </p>
                    <a
                      href="tel:+34931998294"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.phoneNumberWit} />
                    </a>
                    <a
                      href="mailto:info@septeo.com"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                      <FormattedMessage {...messages.emailAddressWit} />
                    </a>
                    <a
                      href="https://maps.app.goo.gl/rFP4TJ7GYNNb1x9c7"
                      target="_blank"
                      rel="noreferrer"
                      className="info-box-text"
                    >
                    <span>
                      <FormattedMessage {...messages.addressDataOne} />
                    </span>
                      <span>
                      <FormattedMessage {...messages.addressDataTwoWit} />
                    </span>
                      <span>
                      <FormattedMessage {...messages.addressDataThreeWit} />
                    </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="wrap-footer-links">
                <a href="https://www.youtube.com/@witbooking"
                   target="_blank"
                   rel="noreferrer"
                   className="footer-rrss">
                  <img
                    src={YouTube}
                    className="rrss-logo"
                    alt="footer-youtube-logo"
                    loading="lazy"
                  />
                </a>
                <a href="https://www.linkedin.com/company/witbooking/"
                   target="_blank"
                   rel="noreferrer"
                   className="footer-rrss">
                  <img
                    src={Linkedin}
                    className="rrss-logo"
                    alt="footer-linkedin-logo"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="main-footer-sign">
            <hr className="footer-hr"/>
            <div className="wrap-footer-end">
              <div>
                <div className="wrap-footer-sign">
                  <p className="footer-sign-text">
                    <FormattedMessage {...messages.rights} />
                  </p>
                </div>
                <img
                  src={Septeo}
                  className="footer-septeo"
                  alt="footer-septeo-logo"
                  loading="lazy"
                />
              </div>
              <div className="footer-info-box">
                <div className="wrap-info-box">
                  <a
                    href="/legal"
                    className="info-box-text"
                  >
                    <FormattedMessage {...messages.legalAdvice} />
                  </a>
                  <a
                    href="/policy"
                    className="info-box-text"
                  >
                    <FormattedMessage {...messages.legalPrivacity} />
                  </a>
                  <a
                    href="/cookies"
                    className="info-box-text"
                  >
                    <FormattedMessage {...messages.legalCookies} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
