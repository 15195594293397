import React from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import * as numbersConstants from "./constants";

// Assets
import Adapt from "../../assets/svg/adapt.svg";
import Arrow from "../../assets/svg/comments-arrow.svg";
import LogoWhite from "../../assets/svg/logo-white.svg";
import Video from "../../assets/img/home/video.svg"

// Videos
import Video1 from "../../assets/video/mix-cool-people.mp4"
import VideoMobile from "../../assets/video/mix-cool-people-mobile.mp4"

import Image1 from "../../assets/img/home/home_1.svg";
import Image2 from "../../assets/img/home/home_2.svg";
import Image3 from "../../assets/img/home/home_3.svg";
import Image4 from "../../assets/img/home/home_4.svg";
import Image5 from "../../assets/img/home/home_5.svg";
import Image6 from "../../assets/img/home/home_6.svg";
import Image7 from "../../assets/img/home/home_7.svg";
import Image8 from "../../assets/img/home/home_8.svg";
import Image9 from "../../assets/img/home/home_9.svg";
import Image10 from "../../assets/img/home/home_10.svg";
import Testimonial2 from "../../assets/img/testimonials/2_Jordi Estalella_179x177.webp";
import Testimonial3 from "../../assets/img/testimonials/3_Meritxell Bañon_179x177.webp";
import Testimonial4 from "../../assets/img/testimonials/4_Meritxell Pérez_179x177.webp";
import Testimonial5 from "../../assets/img/testimonials/5_Pep Diaz_179x177.webp";
import Testimonial6 from "../../assets/img/testimonials/6_Maximiliano Viale_179x177.webp";
import Testimonial7 from "../../assets/img/testimonials/7_Francesc Gonzalez_179x177.webp";
import Testimonial8 from "../../assets/img/testimonials/8_Manuel Cabrera_179x177.webp";
import Testimonial9 from "../../assets/img/testimonials/9_María Rosal_179x177.webp";
import Testimonial10 from "../../assets/img/testimonials/10_Reyes Mañeru.webp";
import Testimonial11 from "../../assets/img/testimonials/11_Itziar.webp";
import {Helmet} from "react-helmet";

export default function Home() {
  const intl = useIntl();

  const messages = defineMessages({
    titleSEO: {
      id: "page.home.titleSEO",
      description: "Booking engine for hotel groups, independent hotels, vacation rentals, apartments, hostels.",
      defaultMessage: "Motor de reservas para cadenas hoteleras, hoteles independientes, apartamentos vacacionales, hostales, hostels, casas rurales",
    },
    descriptionSEO: {
      id: "page.home.descriptionSEO",
      description: "Receive information from the Witbooking booking engine, become a Witbooking distributor",
      defaultMessage: "Recibir información del motor de reservas Witbooking, ser distribuidor de Witbooking",
    },
    keywordSEO: {
      id: "page.home.keywordSEO",
      description: "booking engine, hotel, hotels, hotel chains, tourism, apartments, hostels, rural houses, hotel ecommerce, direct sales, CRS, web design, channel manager",
      defaultMessage: "motor reservas, hotel, hoteles, cadenas hoteleras, turismo, apartamentos, hostels, casas rurales, ecommerce hotelero, venta directa, CRS, diseño web, channel manager",
    },

    numberOne: {
      id: "page.home.numberOne",
      description: "No. 1 in conversion",
      defaultMessage: "Nº1 en conversión",
    },
    fixedVariableCost: {
      id: "page.home.fixedVariableCost",
      description: "Fixed or variable cost",
      defaultMessage: "Coste fijo o variable",
    },
    withoutPermanence: {
      id: "page.home.withoutPermanence",
      description: "Without permanence or license",
      defaultMessage: "Sin permanencia ni licencia",
    },
    metaSearchEngine: {
      id: "page.home.metaSearchEngine",
      description: "Meta search engines",
      defaultMessage: "Metabuscadores sin coste",
    },
    betOnDevelopment: {
      id: "page.home.betOnDevelopment",
      description: "We bet everything on development",
      defaultMessage: "Apostamos todo al desarrollo",
    },
    informationForDecisions: {
      id: "page.home.informationForDecisions",
      description: "Information for your decisions",
      defaultMessage: "Información para tus decisiones",
    },
    persuasiveEsthetics: {
      id: "page.home.persuasiveEsthetics",
      description: "Persuasive Esthetics",
      defaultMessage: "Estética persuasiva",
    },
    listenSuggestions: {
      id: "page.home.listenSuggestions",
      description: "We listen to your suggestions",
      defaultMessage: "Escuchamos tus sugerencias",
    },
    scrollDown: {
      id: "page.home.scrollDown",
      description: "Scroll down instruction",
      defaultMessage: "Scroll Down",
    },
    testedRealClients: {
      id: "page.home.testedRealClients.subtitle",
      description: "Tested on real clients",
      defaultMessage: "Testado en clientes reales",
    },
    testedRealClientsDescription: {
      id: "page.home.testedRealClients.description",
      description: "Tested on real clients description",
      defaultMessage:
        "Hemos realizado tests A/B contra los principales motores de reservas nacionales e internacionales logrando mejoras de hasta el 85% en la conversión a reservas.",
    },
    fixedOrVariableCostTitle: {
      id: "page.home.fixedOrVariableCost.title",
      description: "Fixed or variable cost title",
      defaultMessage: "Coste fijo o variable, tú eliges",
    },
    fixedOrVariableCostSubtitle: {
      id: "page.home.fixedOrVariableCost.subtitle",
      description: "Fixed or variable cost subtitle",
      defaultMessage: "Las comisiones son para las OTA's",
    },
    fixedOrVariableCostDescription: {
      id: "page.home.fixedOrVariableCost.description",
      description: "Fixed or variable cost description",
      defaultMessage:
        "Somos el único motor de reservas de primera línea sin comisión por reserva.",
    },
    withoutPermanenceSubtitle: {
      id: "page.home.withoutPermanence.subtitle",
      description: "Without permanence or license subtitle",
      defaultMessage: "Sólo pagas por su uso",
    },
    withoutPermanenceDescription: {
      id: "page.home.withoutPermanence.description",
      description: "Without permanence or license description",
      defaultMessage:
        "Tú decides cuándo empiezas con nosotros y cuando te vas.",
    },
    metaSearchEngineMarketingTitle: {
      id: "page.home.metaSearchEngineMarketing.title",
      description: "Meta search engines and free marketing title",
      defaultMessage: "Metabuscadores y marketing sin coste",
    },
    metaSearchEngineMarketingSubtitle: {
      id: "page.home.metaSearchEngineMarketing.subtitle",
      description: "Meta search engines and free marketing subtitle",
      defaultMessage: "No queremos intermediar, queremos alinearnos contigo",
    },
    metaSearchEngineMarketingDescription: {
      id: "page.home.metaSearchEngineMarketing.description",
      description: "Meta search engines and free marketing description",
      defaultMessage:
        "Te damos conectividad directa con los principales metabuscadores sin coste adicional.",
    },
    betOnDevelopmentSubtitle: {
      id: "page.home.betOnDevelopment.subtitle",
      description: "We bet everything on development subtitle",
      defaultMessage: "Aumentamos un 61% el presupuesto en I+D",
    },
    betOnDevelopmentDescription: {
      id: "page.home.betOnDevelopment.description",
      description: "We bet everything on development description",
      defaultMessage:
        "Tras 12 años desarrollando peticiones de funcionalidad avanzada para hoteleros pioneros, tenemos muchos síes esperándote.",
    },
    informationForDecisionsSubtitle: {
      id: "page.home.informationForDecisions.subtitle",
      description: "Information for your decisions subtitle",
      defaultMessage: "Aumentamos un 61% el presupuesto en I+D",
    },
    informationForDecisionsDescription: {
      id: "page.home.informationForDecisions.description",
      description: "Information for your decisions description",
      defaultMessage:
        "Tras 12 años desarrollando peticiones de funcionalidad avanzada para hoteleros pioneros, tenemos muchos síes esperándote.",
    },
    callcenter: {
      id: "page.home.callcenter",
      description: "Callcenter description",
      defaultMessage: "Empodera a tu call center",
    },
    callcenterSubtitle: {
      id: "page.home.callcenter.subtitle",
      description: "Callcenter subtitle",
      defaultMessage: "Cotizaciones, pre-reservas y fidelización",
    },
    callcenterDescription: {
      id: "page.home.callcenter.description",
      description: "Callcenter description",
      defaultMessage: "Los call centers necesitan tecnología ágil para potenciar reservas directas de forma humana. Esto incluye:",
    },
    callcenterItem1: {
      id: "page.home.callcenter.description.item1",
      description: "Callcenter description list item1",
      defaultMessage: "La capacidad de bloquear precios y cupos temporalmente.",
    },
    callcenterItem2: {
      id: "page.home.callcenter.description.item2",
      description: "Callcenter description list item2",
      defaultMessage: "Realizar múltiples cotizaciones rápidamente.",
    },
    callcenterItem3: {
      id: "page.home.callcenter.description.item3",
      description: "Callcenter description list item3",
      defaultMessage: "Enviar propuestas con enlaces de pago directo.",
    },
    callcenterItem4: {
      id: "page.home.callcenter.description.item4",
      description: "Callcenter description list item4",
      defaultMessage: "Reservar en nombre de usuarios del sistema de fidelización.",
    },
    fidelize: {
      id: "page.home.fidelize",
      description: "Fidelize your client description",
      defaultMessage: "Fideliza a tu cliente",
    },
    fidelizeSubtitle: {
      id: "page.home.fidelize.subtitle",
      description: "Fidelize your client subtitle",
      defaultMessage: "Para particulares, empresas y agencias",
    },
    fidelizeDescription: {
      id: "page.home.fidelize.description",
      description: "Fidelize your client description",
      defaultMessage:
        "Es importante entender a tus clientes para adaptar tu oferta. Esto se logra con una base de datos en un área privada, que permite ofrecer tarifas especiales y lanzar campañas promocionales, aumentando las reservas directas. El sistema de fidelización también beneficia a empresas y agencias de viajes.",
    },
    persuasiveEstheticsSubtitle: {
      id: "page.home.persuasiveEsthetics.subtitle",
      description: "Persuasive Esthetics subtitle",
      defaultMessage: "Necesitas un motor sexy",
    },
    persuasiveEstheticsDescription: {
      id: "page.home.persuasiveEsthetics.description",
      description: "Persuasive Esthetics description",
      defaultMessage:
        "Tras 12 años desarrollando peticiones de funcionalidad avanzada para hoteleros pioneros, tenemos muchos síes esperándote.",
    },
    listenSuggestionsSubtitle: {
      id: "page.home.listenSuggestions.subtitle",
      description: "We listen to your suggestions subtitle",
      defaultMessage: "No queremos intermediar, queremos alinearnos contigo",
    },
    listenSuggestionsDescription: {
      id: "page.home.listenSuggestions.description",
      description: "We listen to your suggestions description",
      defaultMessage:
        "Te damos conectividad directa con los principales metabuscadores sin coste adicional.",
    },
    solutionsForEveryoneTitle: {
      id: "page.home.solutionsForEveryone.title",
      description: "Solutions for everyone title",
      defaultMessage: "Soluciones para todos",
    },
    solutionsForEveryoneSubtitle: {
      id: "page.home.solutionsForEveryone.subtitle",
      description: "Solutions for everyone subtitle",
      defaultMessage: "Nos adaptamos a todos los tipos de negocio",
    },
    solutionsForEveryoneDescription: {
      id: "page.home.solutionsForEveryone.description",
      description: "Solutions for everyone description",
      defaultMessage:
        "Trabajamos tanto para Establecimientos independientes y Cadenas hoteleras, como para Apartamentos turísticos y Hostels.",
    },
    seeClients: {
      id: "page.home.seeClients",
      description: "See clients",
      defaultMessage: "Ver clientes",
    },
    ourNumbers: {
      id: "page.home.ourNumbers",
      description: "Our numbers",
      defaultMessage: "Nuestros números",
    },
    clients: {
      id: "page.home.clients",
      description: "Clients",
      defaultMessage: "Clientes",
    },
    partners: {
      id: "page.home.partners",
      description: "Partners",
      defaultMessage: "Partners",
    },
    transactions: {
      id: "page.home.transactions",
      description: "Transactions",
      defaultMessage: "Transacciones",
    },
    reservations: {
      id: "page.home.reservations",
      description: "Reservations",
      defaultMessage: "En reservas",
    },
    engineVersions: {
      id: "page.home.engineVersions",
      description: "Engine versions",
      defaultMessage: "Versiones del motor",
    },
    investmentRD: {
      id: "page.home.investmentRD",
      description: "Investment in R&D",
      defaultMessage: "Inversión en I+D",
    },
    since: {
      id: "page.home.since",
      description: "Since",
      defaultMessage: "Desde",
    },
    the: {
      id: "page.home.the",
      description: "Article",
      defaultMessage: "el",
    },
    testimonials: {
      id: "page.home.testimonials",
      description: "testimonials",
      defaultMessage: "Testimoniales",
    },
    testimonialsNameTwo: {
      id: "page.home.testimonialsNameTwo",
      description: "testimonials name two",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentTwo: {
      id: "page.home.testimonialsCommentTwo",
      description: "testimonials comment two",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionTwo: {
      id: "page.home.testimonialsPositionTwo",
      description: "testimonials position two",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameThree: {
      id: "page.home.testimonialsNameThree",
      description: "testimonials name three",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentThree: {
      id: "page.home.testimonialsCommentThree",
      description: "testimonials comment three",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionThree: {
      id: "page.home.testimonialsPositionThree",
      description: "testimonials position eleven",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameEleven: {
      id: "page.home.testimonialsNameEleven",
      description: "testimonials name eleven",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentEleven: {
      id: "page.home.testimonialsCommentEleven",
      description: "testimonials comment eleven",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionEleven: {
      id: "page.home.testimonialsPositionEleven",
      description: "testimonials position three",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameFour: {
      id: "page.home.testimonialsNameFour",
      description: "testimonials name four",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentFour: {
      id: "page.home.testimonialsCommentFour",
      description: "testimonials comment four",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionFour: {
      id: "page.home.testimonialsPositionFour",
      description: "testimonials position four",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameFive: {
      id: "page.home.testimonialsNameFive",
      description: "testimonials name five",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentFive: {
      id: "page.home.testimonialsCommentFive",
      description: "testimonials comment five",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionFive: {
      id: "page.home.testimonialsPositionFive",
      description: "testimonials position five",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameSix: {
      id: "page.home.testimonialsNameSix",
      description: "testimonials name six",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentSix: {
      id: "page.home.testimonialsCommentSix",
      description: "testimonials comment six",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionSix: {
      id: "page.home.testimonialsPositionSix",
      description: "testimonials position six",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameSeven: {
      id: "page.home.testimonialsNameSeven",
      description: "testimonials name seven",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentSeven: {
      id: "page.home.testimonialsCommentSeven",
      description: "testimonials comment seven",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionSeven: {
      id: "page.home.testimonialsPositionSeven",
      description: "testimonials position seven",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameEight: {
      id: "page.home.testimonialsNameEight",
      description: "testimonials name eight",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentEight: {
      id: "page.home.testimonialsCommentEight",
      description: "testimonials comment eight",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionEight: {
      id: "page.home.testimonialsPositionEight",
      description: "testimonials position eight",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameNine: {
      id: "page.home.testimonialsNameNine",
      description: "testimonials name nine",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentNine: {
      id: "page.home.testimonialsCommentNine",
      description: "testimonials comment nine",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionNine: {
      id: "page.home.testimonialsPositionNine",
      description: "testimonials position nine",
      defaultMessage: "Cargo Testimoniales",
    },
    testimonialsNameTen: {
      id: "page.home.testimonialsNameTen",
      description: "testimonials name ten",
      defaultMessage: "Nombre Testimoniales",
    },
    testimonialsCommentTen: {
      id: "page.home.testimonialsCommentTen",
      description: "testimonials comment ten",
      defaultMessage: "Comentario Testimoniales",
    },
    testimonialsPositionTen: {
      id: "page.home.testimonialsPositionTen",
      description: "testimonials position ten",
      defaultMessage: "Cargo Testimoniales",
    },
    slogan1: {
      id: "page.home.slogan1",
      description: "Witbooking slogan",
      defaultMessage: "El motor de reservas de los muy hoteleros",
    },
    sloganDescription1: {
      id: "page.home.sloganDescription1",
      description: "Witbooking slogan description",
      defaultMessage: "Porque la reserva es el inicio de la experiencia",
    },
    videoSlogan1: {
      id: "page.home.videoSlogan1",
      description: "Witbooking slogan for video 1",
      defaultMessage: "¿Motor de reservas"
    },
    videoSlogan2: {
      id: "page.home.videoSlogan2",
      description: "Witbooking slogan for video 2",
      defaultMessage: "idéntico a tu competencia?"
    },
    videoSlogan3: {
      id: "page.home.videoSlogan3",
      description: "Witbooking slogan for video 3",
      defaultMessage: "Marca la diferencia"
    },
  });

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.titleSEO)}</title>
        <meta name="title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta name="description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta name="keywords" content={intl.formatMessage(messages.keywordSEO)}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://metatags.io/"/>
        <meta property="og:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="og:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="og:image"
              content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://metatags.io/"/>
        <meta property="twitter:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="twitter:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="twitter:image"
              content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>

      </Helmet>

      <div className="var-nav-white">
        <aside className="aside-pagenav">
          <div className="wrap-aside-pagenav">
            <ul className="aside-pagenav-list">
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#conversion"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.numberOne} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#coste"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.fixedVariableCost} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#permanencia"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.withoutPermanence} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#metabuscadores"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.metaSearchEngine} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#desarrollo"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.betOnDevelopment} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#informacion"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.informationForDecisions} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#estetica"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.persuasiveEsthetics} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
              <li
                className="aside-pagenav-element js-scrollto"
                data-scroll_to="#sugerencias"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-pagenav-element">
                <span className="pagenav-element-tag">
                  <FormattedMessage {...messages.listenSuggestions} />
                </span>
                  <span className="pagenav-element-dot"></span>
                </div>
              </li>
            </ul>
          </div>
        </aside>
        <section className="home-module">
          <div className="wrap-home-module">
            <div className="home-module-logo">
              <div className="wrap-home-logo">
                <img
                  src=""
                  className="home-logo-graphic"
                  data-lazyimg={LogoWhite}
                  alt="logo blanco Witbooking"
                />
              </div>
            </div>
            <div className="home-module-slider js-home-slider">
              <div className="home-slider-element">
                <div className="wrap-slider-element">
                  <div className="slider-element-video slider-element-video-mobile">
                    <video
                      autoPlay
                      loop
                      muted
                      playsInline
                      id={`home-video-mobile`}
                      className="slider-element-video_element"
                    >
                      <source src={VideoMobile} type="video/mp4"/>
                    </video>
                  </div>
                  <div className="slider-element-video slider-element-video-desktop">
                    <video
                      id={`home-video`}
                      className="slider-element-video_element"
                      muted
                      loop
                      autoPlay
                    >
                      <source src={Video1} type="video/mp4"/>
                    </video>
                  </div>
                </div>
                <div className="home-module-info">
                  <div className="wrap-home-info">
                    <h1 className="home-info-title">
                      <FormattedMessage {...messages.slogan1} />
                    </h1>
                    <p className="home-info-text">
                      <FormattedMessage {...messages.sloganDescription1} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="init-scroll"></div>

        <section className="gallery-info-list">
          <div className="wrap-video">
            <div className="wrap-box">
              <div className="box-video">
                <div className="video-slogan">
                  <span><FormattedMessage {...messages.videoSlogan1} /></span>
                  <span><FormattedMessage {...messages.videoSlogan2} /></span>
                  <span><FormattedMessage {...messages.videoSlogan3} /></span>
                </div>
                <hr className="box-video-divider"/>
                <img
                  data-popup="video"
                  className="mini-video js-toggle-popup"
                  src={Video}
                  alt="video mini"
                />
              </div>
            </div>
          </div>
          <article id="conversion" className="gallery-info var-info-right">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image1}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.numberOne} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage {...messages.testedRealClients} />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.testedRealClientsDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="coste" className="gallery-info var-info-left">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image2}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.fixedOrVariableCostTitle} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage
                        {...messages.fixedOrVariableCostSubtitle}
                      />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.fixedOrVariableCostDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="permanencia" className="gallery-info var-info-right">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image3}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.withoutPermanence} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage {...messages.withoutPermanenceSubtitle} />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.withoutPermanenceDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="metabuscadores" className="gallery-info var-info-left">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image4}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage
                        {...messages.metaSearchEngineMarketingTitle}
                      />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage
                        {...messages.metaSearchEngineMarketingSubtitle}
                      />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.metaSearchEngineMarketingDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="desarrollo" className="gallery-info var-info-right">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image5}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.betOnDevelopment} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage {...messages.betOnDevelopmentSubtitle} />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.betOnDevelopmentDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="informacion" className="gallery-info var-info-left">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image6}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.informationForDecisions} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage
                        {...messages.informationForDecisionsSubtitle}
                      />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.informationForDecisionsDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="estetica" className="gallery-info var-info-right">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image7}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.persuasiveEsthetics} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage
                        {...messages.persuasiveEstheticsSubtitle}
                      />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.persuasiveEstheticsDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="fideliza" className="gallery-info var-info-left">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image9}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.fidelize} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage
                        {...messages.fidelizeSubtitle}
                      />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.fidelizeDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="call_center" className="gallery-info var-info-right">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      alt="gallery-img"
                      aria-label=""
                      src={Image10}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.callcenter} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage
                        {...messages.callcenterSubtitle}
                      />
                    </p>
                    <div className="gallery-content-text">
                      <FormattedMessage
                        {...messages.callcenterDescription}
                      />
                      <ul>
                        <li>
                          <FormattedMessage
                            {...messages.callcenterItem1}
                          />
                        </li>
                        <li>
                          <FormattedMessage
                            {...messages.callcenterItem2}
                          />
                        </li>
                        <li>
                          <FormattedMessage
                            {...messages.callcenterItem3}
                          />
                        </li>
                        <li>
                          <FormattedMessage
                            {...messages.callcenterItem4}
                          />
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article id="sugerencias" className="gallery-info var-info-left">
            <div className="big-wrapper">
              <div className="wrap-gallery-info">
                <div className="gallery-info-multimedia">
                  <div className="wrap-gallery-multimedia">
                    <img
                      className="gallery-multimedia-img"
                      aria-label=""
                      src={Image8}
                    />
                  </div>
                </div>
                <div className="gallery-info-content">
                  <div className="wrap-gallery-content">
                    <h2 className="gallery-content-title">
                      <FormattedMessage {...messages.listenSuggestions} />
                    </h2>
                    <p className="gallery-content-subtitle">
                      <FormattedMessage {...messages.listenSuggestionsSubtitle} />
                    </p>
                    <p className="gallery-content-text">
                      <FormattedMessage
                        {...messages.listenSuggestionsDescription}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </section>

        <section className="info-banner">
          <div className="big-wrapper">
            <div className="wrap-info-banner">
              <h2 className="info-banner-title">
                <FormattedMessage {...messages.solutionsForEveryoneTitle} />
              </h2>
              <img
                src=""
                className="info-banner-img"
                alt="nfo-banner-img"
                data-lazyimg={Adapt}
              />
              <p className="info-banner-subtitle">
                <FormattedMessage {...messages.solutionsForEveryoneSubtitle} />
              </p>
              <p className="info-banner-text">
                <FormattedMessage {...messages.solutionsForEveryoneDescription} />
              </p>
            </div>
          </div>
        </section>

        <section className="info-data">
          <div className="big-wrapper">
            <div className="wrap-info-data">
              <h2 className="info-data-title">
                <FormattedMessage {...messages.ourNumbers} />
              </h2>
              <div className="info-data-list">
                <div className="info-data-element">
                  <div className="wrap-data-element">
                    <p className="data-element-num">
                      {numbersConstants.ourNumbers.clients.slice(0, -3)}
                      <span className="highlight">.</span>
                      {numbersConstants.ourNumbers.clients.slice(-3)}
                      <span className="highlight">+</span>
                    </p>
                    <p className="data-element-title">
                      <FormattedMessage {...messages.clients} />
                    </p>
                  </div>
                </div>
                <div className="info-data-element">
                  <div className="wrap-data-element">
                    <p className="data-element-num">
                      {numbersConstants.ourNumbers.reservations.slice(0, -1)}
                      <span className="highlight">.</span>
                      {numbersConstants.ourNumbers.reservations.slice(-1)}
                      <span className="highlight">M</span>
                    </p>
                    <p className="data-element-title">
                      <FormattedMessage {...messages.transactions} />
                    </p>
                  </div>
                </div>
                <div className="info-data-element">
                  <div className="wrap-data-element">
                    <p className="data-element-num">
                      {numbersConstants.ourNumbers.resValue}
                      <span className="highlight">M €</span>
                    </p>
                    <p className="data-element-title">
                      <FormattedMessage {...messages.reservations} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="comments-banner">
          <div className="big-wrapper">
            <div className="wrap-comments-banner">
              <h2 className="comments-banner-title">
                <FormattedMessage {...messages.testimonials} />
              </h2>
              <div className="comments-banner-box js-comments-slider">
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentFour} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          className="comments-user-icon"
                          src={Testimonial4}
                          alt="user-icon-4"
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameFour} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage
                            {...messages.testimonialsPositionFour}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentEleven} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          className="comments-user-icon"
                          src={Testimonial11}
                          alt="user-icon-11"
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameEleven} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage
                            {...messages.testimonialsPositionEleven}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentTwo} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          className="comments-user-icon"
                          src={Testimonial2}
                          alt="user-icon-2"
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameTwo} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage {...messages.testimonialsPositionTwo} />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentTen} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          className="comments-user-icon"
                          src={Testimonial10}
                          alt="user-icon-10"
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameTen} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage {...messages.testimonialsPositionTen} />
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentThree} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          alt="user-icon-3"
                          className="comments-user-icon"
                          src={Testimonial3}/>
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameThree} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage
                            {...messages.testimonialsPositionThree}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentFive} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          alt="user-icon-5"
                          className="comments-user-icon"
                          src={Testimonial5}
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameFive} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage
                            {...messages.testimonialsPositionFive}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentSix} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          alt="user-icon-6"
                          className="comments-user-icon"
                          src={Testimonial6}
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameSix} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage {...messages.testimonialsPositionSix} />
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentSeven} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          alt="user-icon-7"
                          className="comments-user-icon"
                          src={Testimonial7}
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameSeven} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage
                            {...messages.testimonialsPositionSeven}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentEight} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          className="comments-user-icon"
                          src={Testimonial8}
                          alt="user-icon-8"
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameEight} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage
                            {...messages.testimonialsPositionEight}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="comments-banner-element">
                  <div className="wrap-comments-element">
                    <p className="comments-element-text">
                      <FormattedMessage {...messages.testimonialsCommentNine} />
                    </p>
                    <div className="comments-element-user">
                      <div className="img-circular-container">
                        <img
                          className="comments-user-icon"
                          src={Testimonial9}
                          alt="user-icon-9"
                        />
                      </div>
                      <div className="comments-wrap-data">
                        <p className="comments-user-name">
                          <FormattedMessage {...messages.testimonialsNameNine} />
                        </p>
                        <p className="comments-user-info">
                          <FormattedMessage
                            {...messages.testimonialsPositionNine}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="comments-slick-btns">
                <span className="arrow-btn prev-btn">
                  <img
                    src=""
                    alt="comment-arrow-left"
                    className="arrow-left"
                    data-lazyimg={Arrow}
                  />
                </span>
                <span className="arrow-btn next-btn">
                  <img
                    src=""
                    alt="comment-arrow-right"
                    data-lazyimg={Arrow}
                  />
                </span>
              </div>
            </div>
          </div>
        </section>
        <div id="video" className="popup-module">
          <div className="wrap-popup-module">
            <div
              className="popup-module-backmask popup-box-close js-toggle-popup"
              data-popup="video"
            ></div>
            <div className="popup-module-box video">
              <i
                className="popup-box-close btn-close video js-toggle-popup"
                data-popup="video"
              ></i>
              <iframe
                id="home-video-popup"
                className="video-iframe"
                src="https://www.youtube.com/embed/EGIoAq3bwEY?si=laEnAmhzJaQjtdkH"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen>
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
