import axios from "axios";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {Helmet} from "react-helmet";
import TextareaAutosize from "react-textarea-autosize";
import {getTranslatedCountryList} from "../../utils/intlUtil";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";

// Components
import Contact_img from "../../assets/svg/motor/09_motor_Agencias.svg";
import {useLocaleProvider} from "../../context/localeContext";
import ReCaptcha from "../../common/components/ReCaptcha";

const messages = defineMessages({
  titleSEO: {
    id: "page.contact.titleSEO",
    description: "Contact Witbooking",
    defaultMessage: "Contactar con Witbooking",
  },
  descriptionSEO: {
    id: "page.contact.descriptionSEO",
    description: "Receive information from the Witbooking booking engine, become a Witbooking distributor",
    defaultMessage: "Recibir información del motor de reservas Witbooking, ser distribuidor de Witbooking",
  },
  keywordSEO: {
    id: "page.contact.keywordSEO",
    description: "Information, booking engine, distributor, prescriber",
    defaultMessage: "Información, motor de reservas, distribuidor, prescriptor",
  },
  breadcrumb: {
    id: "page.contact.breadcrumb",
    description: "page contact breadcrumb",
    defaultMessage: "Contacto",
  },
  title: {
    id: "page.contact.pageTitle",
    description: "page contact title",
    defaultMessage: "Contacto",
  },
  intro: {
    id: "page.contact.pageIntro",
    description: "page contact intro",
    defaultMessage:
      "Contacta con nosotros si necesitas más información o una demo online del motor de reservas o de cualquiera de nuestros módulos. Si estás interesado en ser prescriptor o distribuidor estaremos encantados te informaremos de nuestro programa de partners.",
  },
  formDefault: {
    id: "page.contact.formDefault",
    description: "contact form default",
    defaultMessage: "Seleccionar",
  },
  formConsult: {
    id: "page.contact.formConsult",
    description: "contact form consult",
    defaultMessage: "Tipo de consulta",
  },
  formConsultOption1: {
    id: "page.contact.formConsultOption1",
    description: "contact form consult option 1",
    defaultMessage: "Deseo que me llame un agente",
  },
  formConsultOption2: {
    id: "page.contact.formConsultOption2",
    description: "contact form consult option 2",
    defaultMessage: "Deseo recibir información comercial por email.",
  },
  formConsultOption3: {
    id: "page.contact.formConsultOption3",
    description: "contact form consult option 3",
    defaultMessage:
      "Deseo recibir información para ser prescriptor o distribuidor",
  },
  formName: {
    id: "page.contact.formName",
    description: "contact form name",
    defaultMessage: "Nombre",
  },
  formCompany: {
    id: "page.contact.formCompany",
    description: "contact form company",
    defaultMessage: "Empresa",
  },
  formPhone: {
    id: "page.contact.formPhone",
    description: "contact form phone",
    defaultMessage: "Teléfono",
  },
  formEmail: {
    id: "page.contact.formEmail",
    description: "contact form email",
    defaultMessage: "Email",
  },
  formWeb: {
    id: "page.contact.formWeb",
    description: "contact form web",
    defaultMessage: "Web",
  },
  formCountry: {
    id: "page.contact.formCountry",
    description: "contact form copuntry",
    defaultMessage: "País",
  },
  formMessage: {
    id: "page.contact.formMessage",
    description: "contact form message",
    defaultMessage: "Mensaje",
  },
  formMust: {
    id: "page.contact.formMust",
    description: "contact form must",
    defaultMessage: "* Campos obligatorios",
  },
  formConditions: {
    id: "page.contact.formConditions",
    description: "contact form conditions",
    defaultMessage: "Sí, acepto las",
  },
  formConditionsLink: {
    id: "page.contact.formConditionsLink",
    description: "contact form conditions link",
    defaultMessage: "condiciones de privacidad",
  },
  formNews: {
    id: "page.contact.formNews",
    description: "contact form news",
    defaultMessage: "Sí, deseo recibir novedades",
  },
  formBtn: {
    id: "page.contact.formBtn",
    description: "contact form button",
    defaultMessage: "Enviar",
  },
  formSuccess: {
    id: "page.contact.formSuccess",
    description: "contact form success",
    defaultMessage: "Su solicitud ha sido recibida correctamente, nos pondremos en contacto con usted a la mayor brevedad posible.",
  },
  formError: {
    id: "page.contact.formError",
    description: "contact form error",
    defaultMessage: "Ha ocurrido un error.",
  },
    formRequiredFieldsError: {
    id: "page.contact.formRequiredFieldsError",
    description: "contact form error",
    defaultMessage: "*campos obligatorios pendientes",
  },
  select: {
    id: "page.contact.select",
    description: "select",
    defaultMessage: "Seleccionar",
  },
});

export default function Contact({locale}) {
  const [form, setForm] = useState({});
  const [messageError, setMessageError] = useState(null);
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [showFieldMissing, setShowFieldMissing] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const formRef = useRef(null);
  const intl = useIntl();
  const countries = useMemo(() => getTranslatedCountryList(locale), [locale]);
  const [isLoading, setIsLoading] = useState(false);

  const hideMessages = useCallback(() => {
    if (messageError) {
        setMessageError(null);
    }
    if (showMessageSuccess) {
      setShowMessageSuccess(false);
    }
  }, [messageError, showMessageSuccess]);

  const onChange = useCallback(
    (event) => {
      hideMessages();
      const value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      const name = event.target.name;
      setForm((prevState) => ({...prevState, [name]: value}));
    },
    [hideMessages]
  );

  const sendContactForm = useCallback(
    async (e) => {
      e.preventDefault();
      const isValidForm = formRef.current && formRef.current.checkValidity();
      const [firstInvalidField] = formRef.current.querySelectorAll(":invalid");
      if (firstInvalidField) {
        await firstInvalidField.focus();
        const position = firstInvalidField.getBoundingClientRect();
        window.scrollTo(position.left, position.top + window.scrollY - 100);
      }
      if (isValidForm && captchaToken) {
        const country = countries.find((c) => c.value === form.country) || {};
        const data = {
          type: form.type,
          name: form.name,
          company: form.company,
          phone: form.phone,
          email: form.email,
          web: form.web,
          country: country.label,
          message: form.message || "",
          receiveNews: form.receiveNews || false,
          captchaToken,
        };
        try {
          setIsLoading(true);
          await axios.post("/api", JSON.stringify(data), {
            headers: {
              "Content-Type": "application/json",
            },
          });
          formRef.current && formRef.current.reset();
          setForm({});
          setShowMessageSuccess(true);
        } catch (error) {
          console.log(error);
          setMessageError(intl.formatMessage(messages.formError));
        }
        setIsLoading(false);
        setShowFieldMissing(false);
      } else {
        setShowFieldMissing(true);
        setMessageError(intl.formatMessage(messages.formRequiredFieldsError));
      }
    },
    [form, formRef, captchaToken, countries]
  );

  const handleSuccessCaptcha = useCallback(
    (token) => {
      setCaptchaToken(token);
    },
    [setCaptchaToken]
  );

  const localeProvider = useLocaleProvider();
  const isSelectedEnglish = useMemo(
    () => localeProvider.currentLocale === "en-US",
    [localeProvider]
  );

  const sortedCountries = useMemo(() =>
    countries.sort(function (a, b) {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    }), [countries]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.titleSEO)}</title>
        <meta name="title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta name="description" content={intl.formatMessage(messages.titleSEO)}/>
        <meta name="keywords" content={intl.formatMessage(messages.keywordSEO)}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://metatags.io/"/>
        <meta property="og:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="og:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="og:image"
              content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://metatags.io/"/>
        <meta property="twitter:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="twitter:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="twitter:image"
              content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>
      </Helmet>

      <section className="contact-page half-wrapper responsive-multimedia-off">
        <div className="half-breadcrumbs color">
          <ol
            className="breadcrumbs-list"
            itemScope
            itemType="http://schema.org/BreadcrumbList"
          >
            <li
              className="breadcrumbs-list-element"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <a itemProp="item" href="/">
                <span className="breadcrumbs-element-text" itemProp="name">
                  Home
                </span>
              </a>
              <span itemProp="position" content="1">
                &gt;
              </span>
            </li>
            <li
              className="breadcrumbs-list-element active"
              itemProp="itemListElement"
              itemScope
              itemType="http://schema.org/ListItem"
            >
              <a
                itemProp="item"
                href={isSelectedEnglish ? "/contact" : "/contacto"}
              >
                <span className="breadcrumbs-element-text" itemProp="name">
                  <FormattedMessage {...messages.breadcrumb} />
                </span>
              </a>
              <span itemProp="position" content="2">
                &gt;
              </span>
            </li>
          </ol>
        </div>
        <div className="wrapper">
          <div className="wrap-contact-page">
            <header className="contact-page-header">
              <div className="wrap-contact-header">
                <h1 className="contact-header-title">
                  <FormattedMessage {...messages.title} />
                </h1>
              </div>
            </header>
            <div className="contact-page-info">
              <div className="wrap-contact-info">
                <p className="contact-info-text">
                  <FormattedMessage {...messages.intro} />
                </p>
              </div>
            </div>
            <form
              ref={formRef}
              className={`${showFieldMissing && "show-error-form"}`}
            >
              <div className="contact-page-form">
                <div className="wrap-contact-form">
                  <div className="contact-form-fields">
                    <div className="wrap-contact-fields">
                      <div className="contact-fields-element full">
                        <div className="input-element select must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.type &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formConsult} />
                            </span>
                            <select
                              className="input-element-field"
                              name="type"
                              onChange={onChange}
                              value={form.type}
                              required
                            >
                              <option
                                label={intl.formatMessage(messages.select)}
                                value=""
                              >
                                <FormattedMessage {...messages.formDefault} />
                              </option>
                              <option
                                value={intl.formatMessage(
                                  messages.formConsultOption1
                                )}
                              >
                                {intl.formatMessage(
                                  messages.formConsultOption1
                                )}
                              </option>
                              <option
                                value={intl.formatMessage(
                                  messages.formConsultOption2
                                )}
                              >
                                {intl.formatMessage(
                                  messages.formConsultOption2
                                )}
                              </option>
                              <option
                                value={intl.formatMessage(
                                  messages.formConsultOption3
                                )}
                              >
                                {intl.formatMessage(
                                  messages.formConsultOption3
                                )}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element">
                        <div className="input-element user must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.name &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formName} />
                            </span>
                            <input
                              className="input-element-field"
                              type="text"
                              placeholder="..."
                              name="name"
                              onChange={onChange}
                              value={form.name}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element">
                        <div className="input-element user must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.company &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formCompany} />
                            </span>
                            <input
                              className="input-element-field"
                              type="text"
                              placeholder="..."
                              name="company"
                              onChange={onChange}
                              value={form.company}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element">
                        <div className="input-element phone must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.phone &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formPhone} />
                            </span>
                            <input
                              className="input-element-field"
                              type="tel"
                              placeholder="..."
                              name="phone"
                              onChange={onChange}
                              value={form.phone}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element">
                        <div className="input-element email must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.email &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formEmail} />
                            </span>
                            <input
                              className="input-element-field"
                              type="email"
                              placeholder="@..."
                              name="email"
                              onChange={onChange}
                              value={form.email}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element">
                        <div className="input-element user must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.web &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formWeb} />
                            </span>
                            <input
                              className="input-element-field"
                              type="text"
                              placeholder="https://www..."
                              name="web"
                              onChange={onChange}
                              value={form.web}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element">
                        <div className="input-element select must">
                          <div
                            className={`wrap-input-element ${
                              showFieldMissing &&
                              !form.country &&
                              "wrap-input-element-invalid"
                            }`}
                          >
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formCountry} />
                            </span>
                            <select
                              className="input-element-field"
                              name="country"
                              onChange={onChange}
                              value={form.country}
                              required
                            >
                              <option
                                label={intl.formatMessage(messages.select)}
                                value=""
                              ></option>
                              {sortedCountries.map((country) => (
                                <option value={country.value}>
                                  {country.label}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element full">
                        <div className="input-element textarea">
                          <div className="wrap-input-element">
                            <span className="input-element-text">
                              <FormattedMessage {...messages.formMessage} />
                            </span>
                            <TextareaAutosize
                              cacheMeasurements
                              className="input-element-field"
                              name="message"
                              placeholder="..."
                              minRows={2}
                              maxRows={10}
                              onChange={onChange}
                              value={form.message}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="contact-fields-element">
                        <ReCaptcha onSuccess={handleSuccessCaptcha}/>
                      </div>
                    </div>
                  </div>
                  <div className="contact-form-foot">
                    <div className="wrap-form-foot">
                      <div className="form-foot-checks">
                        <div className="wrap-foot-checks">
                          <div className="must-element">
                            <p className="must-element-text">
                              <FormattedMessage {...messages.formMust} />
                            </p>
                          </div>
                          <label className="checkbox-element">
                            <input
                              className="checkbox-input"
                              type="checkbox"
                              name="conditions"
                              value={form.conditions}
                              onChange={onChange}
                              required
                            />
                            <div
                              className={`checkbox-icon ${
                                showFieldMissing &&
                                !form.conditions &&
                                "checkbox-icon-invalid"
                              }`}
                            ></div>
                            <p className="checkbox-text">
                              <FormattedMessage {...messages.formConditions} />{" "}
                              <a
                                href="/policy"
                                target="_blank"
                                className="checkbox-text-link"
                              >
                                <FormattedMessage
                                  {...messages.formConditionsLink}
                                />
                              </a>
                            </p>
                          </label>
                          <label className="checkbox-element">
                            <input
                              className="checkbox-input"
                              type="checkbox"
                              name="receiveNews"
                              value={form.receiveNews}
                              onChange={onChange}
                            />
                            <div className="checkbox-icon"></div>
                            <p className="checkbox-text">
                              <FormattedMessage {...messages.formNews} />
                            </p>
                          </label>
                        </div>
                      </div>
                      <div className="form-foot-btns">
                        <div className="wrap-foot-btns">
                          <div>
                            {showMessageSuccess && (
                              <p className="form-message form-message-success">
                                <FormattedMessage {...messages.formSuccess} />
                              </p>
                            )}
                            {messageError && (
                              <p className="form-message form-message-error">
                                {messageError}
                              </p>
                            )}
                          </div>
                          {!showMessageSuccess && (
                            <button
                              type="button"
                              onClick={sendContactForm}
                              className="btn-corp btn-small"
                              disabled={isLoading}
                            >
                              <p className="btn-corp-text">
                                <FormattedMessage {...messages.formBtn} />
                              </p>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div className="half-multimedia">
              <div className="wrap-half-multimedia">
                <div className="half-multimedia-box">
                  <div className="wrap-multimedia-box">
                    <img
                      alt="contact"
                      className="multimedia-box-element"
                      data-lazyimg={Contact_img}
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
