import React, {useCallback, useMemo, useState} from "react";
import {Helmet} from "react-helmet";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";

import {useLocaleProvider} from "../../context/localeContext";

import BreadCrumbs from "../../layout/breadcrumbs";
import {planTypes, pricingValues, RATE_EMPTY, rateFilters, rates, UNIT_EMPTY, unitSizes} from "./constants";

const includedByRates = [
  {
    message: "include1",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include2",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include3",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include4",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include5",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include6",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include7",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include8",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include9",
    [planTypes.WITBOOKER]: true,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include10",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include11",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include12",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include13",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include14",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include15",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include16",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include17",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
  {
    message: "include18",
    [planTypes.WITBOOKER]: false,
    [planTypes.WITBOOKER_PLUS]: true
  },
];

const messages = defineMessages({
  titleSEO: {
    id: "page.pricing.titleSEO",
    description: "Witbooking booking engine rates",
    defaultMessage: "Precios motor de reservas Witbooking",
  },
  descriptionSEO: {
    id: "page.pricing.descriptionSEO",
    description: "Witbooking booking engine price list",
    defaultMessage: "Tarifa de precios del motor de reservas Witbooking",
  },
  keywordSEO: {
    id: "page.pricing.keywordSEO",
    description:
      "Prices, rates, hotel flat rate, fixed rate, variable rate, booking engine costs",
    defaultMessage:
      "Precios, tarifas, tarifa plana hotel, tarifa fija, tarifa variable, costes motor de reservas",
  },
  breadcrumb: {
    id: "page.pricing.breadcrumb",
    description: "page pricing breadcrumb",
    defaultMessage: "Precios",
  },
  title: {
    id: "page.pricing.pageTitle",
    description: "page pricing title",
    defaultMessage: "Precios",
  },
  intro: {
    id: "page.pricing.pageIntro",
    description: "page pricing intro",
    defaultMessage: "Coste fijo o variable, tú eliges",
  },
  unitFilterTitle: {
    id: "page.pricing.unitFilterTitle",
    description: "pricing form title",
    defaultMessage: "Indica tu tamaño",
  },
  unitFilterSubtitle: {
    id: "page.pricing.unitFilterSubtitle",
    description: "pricing form subtitle",
    defaultMessage: "Selecciona cuántas habitaciones tienes",
  },
  rateFilterTitle: {
    id: "page.pricing.rateFilterTitle",
    description: "rate filter title",
    defaultMessage: "Elige tu tarifa",
  },
  rateFilterSubtitle: {
    id: "page.pricing.rateFilterSubtitle",
    description: "rate filter subtitle",
    defaultMessage: "Selecciona la que más te convenga",
  },
  unitSizeS: {
    id: "page.pricing.unitSizeS",
    description: "pricing form option S",
    defaultMessage: "1-20 habitaciones",
  },
  unitSizeM: {
    id: "page.pricing.unitSizeM",
    description: "pricing form option M",
    defaultMessage: "21-50 habitaciones",
  },
  unitSizeL: {
    id: "page.pricing.unitSizeL",
    description: "pricing form option L",
    defaultMessage: "51-100 habitaciones",
  },
  unitSizeXL: {
    id: "page.pricing.unitSizeXL",
    description: "pricing form option XL",
    defaultMessage: "101-200 habitaciones",
  },
  unitSizeXXL: {
    id: "page.pricing.unitSizeXXL",
    description: "pricing form option XXL",
    defaultMessage: "+200 habitaciones",
  },
  requestDemo: {
    id: "page.banner.btnOne",
    description: "request a demo",
    defaultMessage: "Solicitar demo",
  },
  witbooker: {
    id: "page.pricing.rateRange1",
    defaultMessage: "Witbooker",
  },
  witbookerText: {
    id: "page.pricing.rateRange1Text",
    description: "pricing rates Witbooker text",
  },
  witbookerPlus: {
    id: "page.pricing.rateRange2",
    defaultMessage: "Witbooker+",
  },
  witbookerPlusText: {
    id: "page.pricing.rateRange2Text",
    description: "pricing rates Witbooker text",
  },
  flatRateTitle: {
    id: "page.pricing.flatRateTitle",
    defaultMessage: "Tarifa Plana",
  },
  flatRateFixedFee: {
    id: "page.pricing.flatRateFixedFee",
    defaultMessage: "al mes",
  },
  flatRateAnnualWebSaleCap1: {
    id: "page.pricing.flatRateAnnualWebSaleCap1",
    defaultMessage: "Hasta",
  },
  flatRateAnnualWebSaleCap2: {
    id: "page.pricing.flatRateAnnualWebSaleCap2",
    defaultMessage: "de venta web anual",
  },
  mixedRateTitle: {
    id: "page.pricing.mixedRateTitle",
    defaultMessage: "Tarifa Mixta",
  },
  mixedRateVariable: {
    id: "page.pricing.mixedRateVariable",
    defaultMessage: "al mes",
  },
  mixedRateFixedFee: {
    id: "page.pricing.mixedRateFixedFee",
    defaultMessage: "de las reservas",
  },
  variableRateTitle: {
    id: "page.pricing.variableRateTitle",
    defaultMessage: "Tarifa Variable",
  },
  variableRate: {
    id: "page.pricing.variableRate",
    defaultMessage: "de las reservas",
  },
  variableRateMinimumPerMonth: {
    id: "page.pricing.variableRateMinimumPerMonth",
    defaultMessage: "mínimo mensual",
  },
  mostPopular: {
    id: "page.pricing.mostPopular",
    defaultMessage: "La más popular",
  },
  error: {
    id: "page.pricing.error",
    description: "pricing error message",
    defaultMessage: "No hay precios disponibles con las opciones seleccionadas",
  },
  includeTitle: {
    id: "page.pricing.includeTitle",
    description: "pricing include title",
    defaultMessage: "Incluye",
  },
  include1: {
    id: "page.pricing.include1",
    description: "pricing include one",
    defaultMessage: "Soporte ilimitado online y telefónico",
  },
  include2: {
    id: "page.pricing.include2",
    description: "pricing include two",
    defaultMessage: "Informes y analítica avanzada",
  },
  include3: {
    id: "page.pricing.include3",
    description: "pricing include three",
    defaultMessage: "Reglas de precios dinámicos",
  },
  include4: {
    id: "page.pricing.include4",
    description: "pricing include four",
    defaultMessage:
      "Integración con tu channel manager y descarga de datos de tarjetas de crédito",
  },
  include5: {
    id: "page.pricing.include5",
    description: "pricing include five",
    defaultMessage: "Métodos de pago alternativos",
  },
  include6: {
    id: "page.pricing.include6",
    description: "pricing include six",
    defaultMessage: "Central de reservas (multi-propiedades)",
  },
  include7: {
    id: "page.pricing.include7",
    description: "pricing include seven",
    defaultMessage: "Conectividad con metabuscadores",
  },
  include8: {
    id: "page.pricing.include8",
    description: "pricing include eight",
    defaultMessage: "Cumple normativas de pago PCI-DSS y PSD2",
  },
  include9: {
    id: "page.pricing.include9",
    description: "pricing include nine",
    defaultMessage: "Generador de tarifas combinadas",
  },
  include10: {
    id: "page.pricing.include10",
    description: "pricing include ten",
    defaultMessage: "Sistema de Fidelizacion por niveles",
  },
  include11: {
    id: "page.pricing.include11",
    description: "pricing include eleven",
    defaultMessage: "Modulo de Agencias&Empresas",
  },
  include12: {
    id: "page.pricing.include12",
    description: "pricing include twelve",
    defaultMessage: "Modulo de Callcenter",
  },
  include13: {
    id: "page.pricing.include13",
    description: "pricing include 13",
    defaultMessage: "Modulo de Grupos / Cotizador",
  },
  include14: {
    id: "page.pricing.include14",
    description: "pricing include 14",
    defaultMessage: "Conexión CRM",
  },
  include15: {
    id: "page.pricing.include15",
    description: "pricing include 15",
    defaultMessage: "Tienda online / Cheque regalo",
  },
  include16: {
    id: "page.pricing.include16",
    description: "pricing include twelve",
    defaultMessage: "Hipercustomización",
  },
  include17: {
    id: "page.pricing.include17",
    description: "pricing include 17",
    defaultMessage: "Multi-configuración (para A/B tests)",
  },
  include18: {
    id: "page.pricing.include18",
    description: "pricing include 18",
    defaultMessage: "Vista mapa 3D (resorts, campings, villas, aptos)",
  },
  setUpFee: {
    id: "page.pricing.setUpFee",
    description: "Set-up fee text",
    defaultMessage: "Cuota de alta",
  },
  setUpFeePrice: {
    id: "page.pricing.setUpFeePrice",
    description: "Set-up fee price",
    defaultMessage: "99",
  },
  rateFiltersAll: {
    id: "page.pricing.rateFiltersAll",
    description: "Rate filter all",
    defaultMessage: "All",
  },
  rateFiltersFlat: {
    id: "page.pricing.rateFiltersFlat",
    description: "Rate filter flat",
    defaultMessage: "Flat",
  },
  rateFiltersMixed: {
    id: "page.pricing.rateFiltersMixed",
    description: "Rate filter mixed",
    defaultMessage: "Mixed",
  },
  rateFiltersVariable: {
    id: "page.pricing.rateFiltersVariable",
    description: "Rate filter variable",
    defaultMessage: "Variable",
  },
  noLicense: {
    id: "page.pricing.noLicense",
    description: "Without permanence or license",
    defaultMessage: "Sin permanencia ni licencia",
  },
  registrationFee: {
    id: "page.pricing.registrationFee",
    description: "registration Fee",
    defaultMessage: "Cuota de alta desde 99 €",
  },
  unitEmpty: {
    id: "page.pricing.unitEmpty",
    description: "select an unit",
    defaultMessage: "Seleccionar",
  },
  rateFiltersEmpty: {
    id: "page.pricing.rateFiltersEmpty",
    description: "select a rate",
    defaultMessage: "Seleccionar",
  }
});

const ratesType = [
  {
    key: planTypes.WITBOOKER,
    headerClassName: "rate1"
  },
  {
    key: planTypes.WITBOOKER_PLUS,
    headerClassName: "rate2"
  }
]

export default function Pricing() {
  const [selectedUnitSizes, setSelectedUnitSizes] = useState(UNIT_EMPTY)
  const [selectedFilterRate, setSelectedFilterRate] = useState(RATE_EMPTY)
  const intl = useIntl();

  const localeProvider = useLocaleProvider();

  const isSelectedEnglish = useMemo(
    () => localeProvider.currentLocale === "en-US",
    [localeProvider]
  );

  const changeFilterRate = useCallback((event) => {
    setSelectedFilterRate(event.target.value)
  }, [])

  const changeUnitSizes = useCallback((event) => {
    setSelectedUnitSizes(event.target.value)
  }, [])

  const getPriceRateContent = useCallback((rate, plan) => {
    const price = pricingValues[selectedUnitSizes][
      plan === planTypes.WITBOOKER ? planTypes.WITBOOKER : planTypes.WITBOOKER_PLUS
      ];

    switch (rate) {
      case rates.FLAT:
        return (
          <span>
            <span className="prices-info-container">
              <span
                className={`prices-info-number ${plan === planTypes.WITBOOKER_PLUS ? "prices-info-number-dark" : "prices-info-number-light"}`}>
                <span>
                  {
                    price.flatRate.fixedFee
                  } €
                </span>
              </span>
              <span className="prices-info-number-text">
                <FormattedMessage {...messages.flatRateFixedFee} />
              </span>
            </span>
            <span className="prices-info-text">
              {
                `${intl.formatMessage(messages.flatRateAnnualWebSaleCap1)} ${price.flatRate.annualWebSaleCap}€ ${intl.formatMessage(messages.flatRateAnnualWebSaleCap2)}`
              }
            </span>
          </span>
        )
      case rates.MIXED:
        return (
          <span className="prices-info-container">
            <span
              className={`prices-info-number ${plan === planTypes.WITBOOKER_PLUS ? "prices-info-number-dark" : "prices-info-number-light"}`}>
              <span>
                {
                  price.mixedRate.fixedFee
                } €
                <span className="prices-info-text">
                  <FormattedMessage
                    {...messages.mixedRateVariable}
                  />
                </span>
              </span>
              <span className="prices-info-plus">+</span>
              <span>
                {
                  price.mixedRate.variable
                } %
                <span className="prices-info-text">
                  <FormattedMessage
                    {...messages.mixedRateFixedFee}
                  />
                </span>
              </span>
            </span>
          </span>
        )
      case rates.VARIABLE:
        return (
          <span>
            <span className="prices-info-container">
              <span
                className={`prices-info-number ${plan === planTypes.WITBOOKER_PLUS ? "prices-info-number-dark" : "prices-info-number-light"}`}>
                <span>
                  {
                    price.variableRate.variable
                  } %
                </span>
              </span>
              <span className="prices-info-number-text">
                <FormattedMessage
                  {...messages.variableRate}
                />
              </span>
            </span>
            <span className="prices-info-text">
              {
                `${price.variableRate.minimumPerMonth}€ ${intl.formatMessage(messages.variableRateMinimumPerMonth)}`
              }
            </span>
          </span>
        )
      default:
        return <></>
    }
  }, [selectedUnitSizes, intl])

  const withoutResults = useMemo(() => selectedFilterRate === RATE_EMPTY || selectedUnitSizes === UNIT_EMPTY, [selectedUnitSizes, selectedFilterRate])

  const allRates = useMemo(() => {
    if (withoutResults) {
      return [];
    }
    return ([
      {
        key: rates.FLAT,
        title: "flatRateTitle",
        [planTypes.WITBOOKER]: getPriceRateContent(rates.FLAT, planTypes.WITBOOKER),
        [planTypes.WITBOOKER_PLUS]: getPriceRateContent(rates.FLAT, planTypes.WITBOOKER_PLUS)
      },
      {
        key: rates.MIXED,
        title: "mixedRateTitle",
        [planTypes.WITBOOKER]: getPriceRateContent(rates.MIXED, planTypes.WITBOOKER),
        [planTypes.WITBOOKER_PLUS]: getPriceRateContent(rates.MIXED, planTypes.WITBOOKER_PLUS),
        mostPopular: planTypes.WITBOOKER_PLUS
      },
      {
        key: rates.VARIABLE,
        title: "variableRateTitle",
        [planTypes.WITBOOKER]: getPriceRateContent(rates.VARIABLE, planTypes.WITBOOKER),
        [planTypes.WITBOOKER_PLUS]: getPriceRateContent(rates.VARIABLE, planTypes.WITBOOKER_PLUS)
      }
    ])
  }, [getPriceRateContent, withoutResults]);

  const filteredRates = useMemo(() => {
    if (withoutResults) {
      return []
    }
    if (selectedFilterRate === rates.ALL) {
      return allRates;
    }
    return allRates.filter((rate) => rate.key === selectedFilterRate)
  }, [allRates, selectedFilterRate, withoutResults]);

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.titleSEO)}</title>
        <meta name="title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta
          name="description"
          content={intl.formatMessage(messages.descriptionSEO)}
        />
        <meta
          name="keywords"
          content={intl.formatMessage(messages.keywordSEO)}
        />

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://metatags.io/"/>
        <meta
          property="og:title"
          content={intl.formatMessage(messages.titleSEO)}
        />
        <meta
          property="og:description"
          content={intl.formatMessage(messages.descriptionSEO)}
        />
        <meta
          property="og:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://metatags.io/"/>
        <meta
          property="twitter:title"
          content={intl.formatMessage(messages.titleSEO)}
        />
        <meta
          property="twitter:description"
          content={intl.formatMessage(messages.descriptionSEO)}
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />
      </Helmet>

      <BreadCrumbs
        breadcrumbText={intl.formatMessage(messages.breadcrumb)}
        pageTitle={intl.formatMessage(messages.title)}
        pageIntro={intl.formatMessage(messages.intro)}
      />

      <section className="prices-module">
        <div className="big-wrapper">
          <div className="wrap-prices-module">
            <div className="prices-module-filter">
              <div className="wrap-prices-filter">
                <div className="prices-filter-header">
                  <p className="prices-filter-title">
                    <FormattedMessage {...messages.unitFilterTitle} />
                  </p>
                  <p className="prices-filter-subtitle">
                    <FormattedMessage {...messages.unitFilterSubtitle} />
                  </p>
                </div>
                <div className="prices-filter-form">
                  <div className="prices-filter-field">
                    <select
                      className="form-select filter-field-select"
                      onChange={changeUnitSizes}
                      value={selectedUnitSizes}>
                      {
                        unitSizes.map(({key, name}) => (
                          <option value={key}>
                            {intl.formatMessage(messages[name])}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
              <div className="wrap-prices-filter">
                <div className="prices-filter-header">
                  <p className="prices-filter-title">
                    <FormattedMessage {...messages.rateFilterTitle} />
                  </p>
                  <p className="prices-filter-subtitle">
                    <FormattedMessage {...messages.rateFilterSubtitle} />
                  </p>
                </div>
                <div className="prices-filter-form">
                  <div className="prices-filter-field">
                    <select
                      className="form-select filter-field-select"
                      onChange={changeFilterRate}
                      value={selectedFilterRate}>
                      {
                        rateFilters.map(({key, name}) => (
                          <option value={key}>
                            {intl.formatMessage(messages[name])}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="prices-module-container">
              <div className="prices-module-list">
                <div className="wrap-prices-list">
                  <div className="prices-list-box" data-filter="1">
                    {
                      ratesType.map(({key: rateKey, headerClassName, mostPopular}) => {
                          return (
                            <div className="prices-list-element">
                              <div className={`wrap-prices-element ${withoutResults && "dark-background-element"}`}>
                                <div
                                  className={`prices-element-header ${withoutResults ? "notResult" : headerClassName}`}>
                                  <p className="prices-element-title">
                                    <FormattedMessage {...messages[rateKey]} />
                                  </p>
                                  <p className="prices-element-subtitle">
                                    <FormattedMessage {...messages[`${rateKey}Text`]} />
                                  </p>
                                </div>
                                {
                                  filteredRates.map((rate) => {
                                    if (rate.key === "variable" && rateKey === "witbookerPlus"){
                                      return null
                                    }
                                    return (
                                    <div
                                      className={`prices-element-info ${rate.mostPopular === rateKey && "prices-element-info-popular"} ${selectedFilterRate !== rates.ALL && "filtered-prices-element"}`}>
                                      {
                                        rate.mostPopular === rateKey &&
                                        <span className="prices-list-most-popular">
                                        <FormattedMessage {...messages.mostPopular} />
                                      </span>
                                      }
                                      <span className="prices-info-title">
                                      <FormattedMessage
                                        {...messages[rate.title]}
                                      />
                                    </span>
                                      <span className="prices-info-container">
                                      {rate[rateKey]}
                                    </span>
                                    </div>
                                  )})
                                }
                              </div>
                            </div>
                          )
                        }
                      )
                    }
                  </div>
                  <div className="empty-message">
                    <div className="wrap-empty-message">
                      <p className="empty-message-text">
                        <FormattedMessage {...messages.error} />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="prices-module-extra">
                <div className="wrap-prices-extra">
                  <ul className="prices-extra-list">
                    {
                      includedByRates.map((item) => (
                        <li className="prices-extra-element">
                          <div className="wrap-extra-element">
                            <p className={`extra-element-text ${!item[planTypes.WITBOOKER] && "not-available"}`}>
                              <FormattedMessage {...messages[item.message]} />
                            </p>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  <div className="prices-foot-btn">
                    <div className="prices-button button-first">
                      <a
                        href={
                          isSelectedEnglish ? "/contact" : "/contacto"
                        }
                        className="btn-corp-text"
                      >
                        <FormattedMessage {...messages.requestDemo} />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="wrap-prices-extra">
                  <ul className="prices-extra-list">
                    {
                      includedByRates.map((item) => (
                        <li className="prices-extra-element">
                          <div className="wrap-extra-element">
                            <p
                              className={`extra-element-text ${!item[planTypes.WITBOOKER_PLUS] && "not-available"}`}>
                              <FormattedMessage {...messages[item.message]} />
                            </p>
                          </div>
                        </li>
                      ))
                    }
                  </ul>
                  <div className="prices-foot-btn">
                    <div className="prices-button button-second">
                      <a
                        href={
                          isSelectedEnglish ? "/contact" : "/contacto"
                        }
                        className="btn-corp-text"
                      >
                        <FormattedMessage {...messages.requestDemo} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="prices-footer">
              <p>
                <FormattedMessage {...messages.noLicense} />
              </p>
              <p>
                <FormattedMessage {...messages.registrationFee} />
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
