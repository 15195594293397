import React, {useCallback, useMemo} from "react";
import {defineMessages, FormattedMessage} from "react-intl";
import {useLocaleProvider} from "../context/localeContext";

// Assets
import Logo from "../assets/svg/logo.svg";
import LogoWhite from "../assets/svg/logo-white.svg";
import User from "../assets/svg/user.svg"
import Language from "../assets/svg/language.svg"
import ArrowNav from "../assets/svg/nav_arrow.svg"

const messages = defineMessages({
  close: {
    id: "page.nav.close",
    description: "Nav option close",
    defaultMessage: "Cerrar",
  },
  home: {
    id: "page.nav.home",
    description: "Nav option home",
    defaultMessage: "Home",
  },
  beDistributor: {
    id: "page.nav.beDistributor",
    description: "Nav option be distributor",
    defaultMessage: "Ser distribuidor",
  },
  menu: {
    id: "page.nav.menu",
    description: "Nav option menu",
    defaultMessage: "Menú",
  },
  bookingEngine: {
    id: "page.nav.bookingEngine",
    description: "Nav option booking engine",
    defaultMessage: "Motor de Reservas",
  },
  selectAPoint: {
    id: "page.nav.selectAPoint",
    description: "Nav option select a point",
    defaultMessage: "Selecciona un punto",
  },
  usability: {
    id: "page.nav.usability",
    description: "Nav option usability",
    defaultMessage: "Usabilidad",
  },
  promotions: {
    id: "page.nav.promotions",
    description: "Nav option promotions",
    defaultMessage: "Promociones",
  },
  extraAndPackages: {
    id: "page.nav.extraAndPackages",
    description: "Nav option extra and packages",
    defaultMessage: "Extras y paquetes",
  },
  tradePolicy: {
    id: "page.nav.tradePolicy",
    description: "Nav option trade policy",
    defaultMessage: "Política comercial",
  },
  chains: {
    id: "page.nav.chains",
    description: "Nav option chains",
    defaultMessage: "Cadenas",
  },
  comunications: {
    id: "page.nav.comunications",
    description: "Nav option comunications",
    defaultMessage: "Comunicaciones",
  },
  personalization: {
    id: "page.nav.personalization",
    description: "Nav option personalization",
    defaultMessage: "Personalizaciones",
  },
  reportsAndAnalytic: {
    id: "page.nav.reportsAndAnalytic",
    description: "Nav option reports and analytic",
    defaultMessage: "Informes y analítica avanzada",
  },
  agenciesEventsGroups: {
    id: "page.nav.agenciesEventsGroups",
    description: "Nav option agencies, events and groups",
    defaultMessage: "Agencias, eventos y grupos",
  },
  metaSearchEngine: {
    id: "page.nav.metaSearchEngine",
    description: "Nav option meta search engine",
    defaultMessage: "Metabuscadores",
  },
  paymentSystem: {
    id: "page.nav.paymentSystem",
    description: "Nav option payment system",
    defaultMessage: "Sistema de pago",
  },
  modules: {
    id: "page.nav.modules",
    description: "Nav option modules",
    defaultMessage: "Modulos",
  },
  webExpress: {
    id: "page.nav.webExpress",
    description: "Nav option web express",
    defaultMessage: "Web express",
  },
  onlineStore: {
    id: "page.nav.onlineStore",
    description: "Nav option online store",
    defaultMessage: "Tienda online",
  },
  loyaltySystem: {
    id: "page.nav.loyaltySystem",
    description: "Nav option loyalty system",
    defaultMessage: "Sistema de fidelización",
  },
  smartPayment: {
    id: "page.nav.smartPayment",
    description: "Nav option smart payment",
    defaultMessage: "Pagos smart",
  },
  integrations: {
    id: "page.nav.integrations",
    description: "Nav option integrations",
    defaultMessage: "Integraciones",
  },
  channelManager: {
    id: "page.nav.channelManager",
    description: "Nav option channel manager",
    defaultMessage: "Channel manager",
  },
  metaManagers: {
    id: "page.nav.metaManagers",
    description: "Nav option meta-managers",
    defaultMessage: "Meta-managers",
  },
  paymentGateways: {
    id: "page.nav.paymentGateways",
    description: "Nav option payment gateways",
    defaultMessage: "Pasarelas de pago",
  },
  financing: {
    id: "page.nav.financing",
    description: "Nav option financing",
    defaultMessage: "Financiación",
  },
  pms: {
    id: "page.nav.pms",
    description: "Nav option pms",
    defaultMessage: "PMS",
  },
  analyticSystems: {
    id: "page.nav.analyticSystems",
    description: "Nav option analytic systems",
    defaultMessage: "Sistemas de analítica",
  },
  comparisonWidgets: {
    id: "page.nav.comparisonWidgets",
    description: "Nav option comparison widgets",
    defaultMessage: "Widgets de comparación",
  },
  chatbots: {
    id: "page.nav.chatbots",
    description: "Nav option chatbots",
    defaultMessage: "Chatbots",
  },
  callCenter: {
    id: "page.nav.callCenter",
    description: "Nav option callCenter",
    defaultMessage: "callCenter",
  },
  virtualCallCenter: {
    id: "page.nav.virtualCallCenter",
    description: "Nav option Virtual Call Center",
    defaultMessage: "callCenter",
  },
  travelInsurance: {
    id: "page.nav.travelInsurance",
    description: "Nav option travel insurance",
    defaultMessage: "Seguros de viaje",
  },
  marketing: {
    id: "page.nav.marketing",
    description: "Nav option marketing",
    defaultMessage: "Marketing",
  },
  prices: {
    id: "page.nav.prices",
    description: "Nav option prices",
    defaultMessage: "Prices",
  },
  blog: {
    id: "page.nav.blog",
    description: "Nav option blog",
    defaultMessage: "Blog",
  },
  login: {
    id: "page.nav.login",
    description: "Nav option login",
    defaultMessage: "Login",
  },
  contact: {
    id: "page.nav.contact",
    description: "Nav option contact",
    defaultMessage: "Contacto",
  },
  selectALanguage: {
    id: "page.nav.selectALanguage",
    description: "Nav option select a language",
    defaultMessage: "Selecciona un idioma",
  },
  meetOurEngine: {
    id: "page.nav.meetOurEngine",
    description: "Nav option meet our engine",
    defaultMessage: "Conoce nuestro motor en 2 minutos",
  },
  seeEngineDemo: {
    id: "page.nav.seeEngineDemo",
    description: "Nav option see engine demo",
    defaultMessage: "Ver demo motor",
  },
});

export default function Nav() {
  const localeProvider = useLocaleProvider();
  const changeLanguague = useCallback(
    (e, locale) => {
      e.preventDefault();
      const languagePopup = document.getElementById("languages");
      languagePopup.classList.remove("open");
      localeProvider.setLocale(locale);
    },
    [localeProvider]
  );

  const isSelectedEnglish = useMemo(
    () => localeProvider.currentLocale === "en-US",
    [localeProvider]
  );

  return (
    <>
      <nav className="main-menu">
        <div className="wrap-main-menu">
          <div className="main-menu-logo">
            <a href="/">
              <img
                src={Logo}
                className="menu-logo-element"
                alt="logo Witbooking"
                loading="lazy"
              />
              <img
                src={LogoWhite}
                className="menu-logo-element white"
                alt="logo blanco Witbooking"
                loading="lazy"
              />
            </a>
          </div>
          <div className="main-menu-links">
            <div className="wrap-menu-links">
              <div
                className="menu-links-close js-toggle-menu"
                role="button"
                tabIndex="0"
              >
                <p className="links-close-text">
                  <FormattedMessage {...messages.close} />
                </p>
                <i className="links-close-icon icon-close"></i>
              </div>
              <div className="menu-links-list">
                <div className="menu-links-element dropdown">
                  <a href="/" className="menu-links-element home-element">
                    <div className="wrap-links-element">
                      <p className="links-element-text">
                        <FormattedMessage {...messages.home} />
                      </p>
                    </div>
                  </a>
                  <a
                    href={
                      isSelectedEnglish
                        ? "/booking-engine"
                        : "/motor-de-reservas"
                    }
                    className="wrap-links-element js-toggle-submenu"
                    role="button"
                    tabIndex="0"
                  >
                    <p className="links-element-text">
                      <FormattedMessage {...messages.bookingEngine} />
                      <img
                        src={ArrowNav}
                        className="menu-logo-element white"
                        alt="arrow nav icon"
                        loading="lazy"
                      />
                    </p>
                  </a>
                  {/* <!-- Dropdown submenu --> */}
                  <div className="main-submenu">
                    <div className="main-submenu-blackmask js-close-submenu"></div>
                    <div className="wrap-main-submenu">
                      <div
                        className="main-submenu-close js-close-submenu"
                        role="button"
                        tabIndex="0"
                      >
                        <p className="submenu-close-text">
                          <FormattedMessage {...messages.close} />
                        </p>
                        <i className="submenu-close-icon icon-close"></i>
                      </div>
                      <p className="main-submenu-title">
                        <FormattedMessage {...messages.selectAPoint} />
                      </p>
                      <div className="main-submenu-list">
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#usabilidad`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.usability} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#promociones`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.promotions} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#extras-paquetes`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage
                                {...messages.extraAndPackages}
                              />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#politica-comercial`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.tradePolicy} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#cadenas`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.chains} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#comunicacion`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.comunications} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#personalizacion`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.personalization} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#informes-y-analitica-avanzada`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage
                                {...messages.reportsAndAnalytic}
                              />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#agencias-eventos-grupos`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage
                                {...messages.agenciesEventsGroups}
                              />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#metabuscadores`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage
                                {...messages.metaSearchEngine}
                              />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/booking-engine"
                              : "/motor-de-reservas"
                          }#sistemas-de-pago`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.paymentSystem} />
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!-- // Dropdown submenu // --> */}
                </div>
                <div className="menu-links-element dropdown">
                  <a
                    href={`${isSelectedEnglish ? "/add-on" : "/modulos"}`}
                    className="wrap-links-element js-toggle-submenu"
                    role="button"
                    tabIndex="0"
                  >
                    <p className="links-element-text">
                      <FormattedMessage {...messages.modules} />
                      <img
                        src={ArrowNav}
                        className="menu-logo-element white"
                        alt="arrow nav icon"
                        loading="lazy"
                      />
                    </p>
                  </a>
                  {/* <!-- Dropdown submenu --> */}
                  <div className="main-submenu">
                    <div className="main-submenu-blackmask js-close-submenu"></div>
                    <div className="wrap-main-submenu">
                      <div
                        className="main-submenu-close js-close-submenu"
                        role="button"
                        tabIndex="0"
                      >
                        <p className="submenu-close-text">
                          <FormattedMessage {...messages.close} />
                        </p>
                        <i className="submenu-close-icon icon-close"></i>
                      </div>
                      <p className="main-submenu-title">
                        <FormattedMessage {...messages.selectAPoint} />
                      </p>
                      <div className="main-submenu-list">
                        <a
                          href={`${
                            isSelectedEnglish ? "/add-on" : "/modulos"
                          }#tienda-online`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.onlineStore} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish ? "/add-on" : "/modulos"
                          }#pagos-smart`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.smartPayment} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish ? "/add-on" : "/modulos"
                          }#sistema-fidelizacion`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.loyaltySystem} />
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!-- // Dropdown submenu // --> */}
                </div>
                <div className="menu-links-element dropdown">
                  <a
                    href={
                      isSelectedEnglish ? "/integrations" : "/integraciones"
                    }
                    className="wrap-links-element js-toggle-submenu"
                    role="button"
                    tabIndex="0"
                  >
                    <p className="links-element-text">
                      <FormattedMessage {...messages.integrations} />
                      <img
                        src={ArrowNav}
                        className="menu-logo-element white"
                        alt="arrow nav icon"
                        loading="lazy"
                      />
                    </p>
                  </a>
                  {/* <!-- Dropdown submenu --> */}
                  <div className="main-submenu">
                    <div className="main-submenu-blackmask js-close-submenu"></div>
                    <div className="wrap-main-submenu">
                      <div
                        className="main-submenu-close js-close-submenu"
                        role="button"
                        tabIndex="0"
                      >
                        <p className="submenu-close-text">
                          <FormattedMessage {...messages.close} />
                        </p>
                        <i className="submenu-close-icon icon-close"></i>
                      </div>
                      <p className="main-submenu-title">
                        <FormattedMessage {...messages.selectAPoint} />
                      </p>
                      <div className="main-submenu-list">
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#channel-managers`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.channelManager} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#metabuscadores`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage
                                {...messages.metaSearchEngine}
                              />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#meta-managers`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.metaManagers} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#pasarelas-de-pago`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.paymentGateways} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#financiacion`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.financing} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#pms`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.pms} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#sistemas-de-analitica`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.analyticSystems} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#widgets-de-comparacion`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage
                                {...messages.comparisonWidgets}
                              />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#chatbots`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.chatbots} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#callCenters`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.callCenter} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#virtualCallCenter`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.virtualCallCenter} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#seguros-de-viaje`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.travelInsurance} />
                            </p>
                          </div>
                        </a>
                        <a
                          href={`${
                            isSelectedEnglish
                              ? "/integrations"
                              : "/integraciones"
                          }#marketing`}
                          className="main-submenu-element"
                        >
                          <div className="wrap-submenu-element">
                            <p className="submenu-element-text">
                              <FormattedMessage {...messages.marketing} />
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!-- // Dropdown submenu // --> */}
                </div>
                <a
                  href={isSelectedEnglish ? "/rates" : "/precios"}
                  className="menu-links-element"
                >
                  <div className="wrap-links-element">
                    <p className="links-element-text">
                      <FormattedMessage {...messages.prices} />
                    </p>
                  </div>
                </a>
                <a
                  href="https://www.septeo.com/es/blog/hospitality"
                  target="_blank"
                  rel="noreferrer"
                  className="menu-links-element"
                >
                  <div className="wrap-links-element">
                    <p className="links-element-text">
                      <FormattedMessage {...messages.blog} />
                    </p>
                  </div>
                </a>
              </div>
              <div className="menu-sublinks-list">
                <a href={isSelectedEnglish ? "/contact" : "/contacto"} className="menu-sublinks-element">
                  <div className="wrap-sublinks-element white-color">
                    <p className="sublinks-element-text">
                      <FormattedMessage {...messages.beDistributor} />
                    </p>
                  </div>
                </a>
                <a
                  href={isSelectedEnglish ? "/contact" : "/contacto"}
                  className="menu-sublinks-element"
                >
                  <div className="wrap-sublinks-element main-color">
                    <p className="sublinks-element-text">
                      <FormattedMessage {...messages.contact} />
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="main-menu-subtns">
            <div
              className="main-menu-btn js-toggle-menu"
              role="button"
              tabIndex="0"
            >
              <div className="menu-btn-box">
                <i className="menu-btn-icon icon-menu"></i>
                <p className="menu-btn-text">
                  <FormattedMessage {...messages.menu} />
                </p>
              </div>
              <div className="menu-btn-box toclose">
                <i className="menu-btn-icon icon-close"></i>
                <p className="menu-btn-text">
                  <FormattedMessage {...messages.close} />
                </p>
              </div>
            </div>
            <a
              href="https://admin.witbooking.com/v6/usuarios/login?"
              rel="noreferrer"
              target="_blank"
              className="menu-subtns-element"
            >
              <div>
                <img
                  src={User}
                  className="menu-logo-element white"
                  alt="user icon"
                  loading="lazy"
                />
              </div>
            </a>
            <div
              className="menu-subtns-element js-toggle-popup"
              data-popup="languages"
              role="button"
              tabIndex="0"
            >
              <img
                src={Language}
                className="menu-logo-element white"
                alt="language icon"
                loading="lazy"
              />
            </div>
            <div className="menu-sublinks-list-desktop">
              <a href={isSelectedEnglish ? "/contact" : "/contacto"} className="menu-sublinks-element">
                <div className="wrap-sublinks-element white-color">
                  <p className="sublinks-element-text">
                    <FormattedMessage {...messages.beDistributor} />
                  </p>
                </div>
              </a>
              <a
                href={isSelectedEnglish ? "/contact" : "/contacto"}
                className="menu-sublinks-element"
              >
                <div className="wrap-sublinks-element main-color">
                  <p className="sublinks-element-text">
                    <FormattedMessage {...messages.contact} />
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </nav>
      <div id="languages" className="popup-module">
        <div className="wrap-popup-module">
          <div
            className="popup-module-backmask popup-box-close js-toggle-popup"
            data-popup="languages"
          ></div>
          <div className="popup-module-box">
            <i
              className="popup-box-close btn-close js-toggle-popup"
              data-popup="languages"
            ></i>
            <div className="wrap-popup-box">
              <p className="popup-box-subtitle">
                <FormattedMessage {...messages.selectALanguage} />
              </p>
              <div className="popup-box-languages">
                <div
                  onClick={(e) => changeLanguague(e, "en-US")}
                  rel="alternate"
                  className={`btn-languages btn-corp ${
                    isSelectedEnglish && "active"
                  }`}
                >
                  <p className="btn-corp-text">English</p>
                </div>
                <div
                  onClick={(e) => changeLanguague(e, "es-ES")}
                  rel="alternate"
                  className={`btn-languages btn-corp ${
                    !isSelectedEnglish && "active"
                  }`}
                >
                  <p className="btn-corp-text">Español</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

/* --Oculto temporalmente--
    <aside className="aside-action">
      <div className="wrap-aside-action">
        <p className="aside-action-text">
          <FormattedMessage {...messages.meetOurEngine} />
        </p>
        <a href="" className="aside-action-btn">
          <p className="action-btn-text">
            <FormattedMessage {...messages.seeEngineDemo} />
          </p>
        </a>
      </div>
    </aside>
*/
