import React from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

// Assets
import Channel_1 from "../../assets/svg/integrations/1_Channel_Managers/1_site_minder.svg";
import Channel_2 from "../../assets/svg/integrations/1_Channel_Managers/2_yield_planet.svg";
import Channel_3 from "../../assets/svg/integrations/1_Channel_Managers/3_dingus.svg";
import Channel_3_1 from "../../assets/svg/integrations/1_Channel_Managers/3_acigroup.svg";
import Channel_3_2 from "../../assets/svg/integrations/1_Channel_Managers/3_guest_group.svg";
import Channel_4 from "../../assets/svg/integrations/1_Channel_Managers/4_e-gds.svg";
import Channel_5 from "../../assets/svg/integrations/1_Channel_Managers/5_icnea.svg";
import Channel_6 from "../../assets/svg/integrations/1_Channel_Managers/6_prestige.svg";
import Channel_7 from "../../assets/svg/integrations/1_Channel_Managers/7_party_rate.svg";
import Channel_8 from "../../assets/svg/integrations/1_Channel_Managers/8_rate_tiger.svg";
import Channel_9 from "../../assets/svg/integrations/1_Channel_Managers/9_rate_gain.svg";
import Channel_10 from "../../assets/svg/integrations/1_Channel_Managers/10_synxis.svg";
import Channel_11 from "../../assets/svg/integrations/1_Channel_Managers/12_roomcloud.svg";

import Metas_1 from "../../assets/svg/integrations/2_Metabuscadores/1_tripadvisor.svg";
import Metas_2 from "../../assets/svg/integrations/2_Metabuscadores/2_trivago.svg";
import Metas_3 from "../../assets/svg/integrations/2_Metabuscadores/3_goole_hotel_ads.svg";

import Metamanager_1 from "../../assets/svg/integrations/3_Meta-managers/1_pushtech.svg";
import Metamanager_2 from "../../assets/svg/integrations/3_Meta-managers/2_my_hotel_shop.svg";
import Metamanager_3 from "../../assets/svg/integrations/3_Meta-managers/3_derbysoft.svg";

import Payments_1 from "../../assets/svg/integrations/4_Pasarelas_de_pago/1_redsys.svg";
import Payments_2 from "../../assets/svg/integrations/4_Pasarelas_de_pago/2_stipe.svg";
import Payments_3 from "../../assets/svg/integrations/4_Pasarelas_de_pago/3_addon_payment.svg";
import Payments_4 from "../../assets/svg/integrations/4_Pasarelas_de_pago/4_paynopain.svg";
import Payments_5 from "../../assets/svg/integrations/4_Pasarelas_de_pago/5_paycomet.svg";
import Payments_6 from "../../assets/svg/integrations/4_Pasarelas_de_pago/6_adyen.svg";
import Payments_7 from "../../assets/svg/integrations/4_Pasarelas_de_pago/7_paypal.svg";
import Payments_8 from "../../assets/svg/integrations/4_Pasarelas_de_pago/8_google_pay.svg";
import Payments_9 from "../../assets/svg/integrations/4_Pasarelas_de_pago/9_apple_pay.svg";
import Payments_10 from "../../assets/svg/integrations/4_Pasarelas_de_pago/10_freedompay.svg";
import Payments_11 from "../../assets/svg/integrations/4_Pasarelas_de_pago/11_bizum.svg";
import Payments_12 from "../../assets/svg/integrations/4_Pasarelas_de_pago/12_sofort.svg";
import Payments_13 from "../../assets/svg/integrations/4_Pasarelas_de_pago/13_giropay.svg";
import Payments_14 from "../../assets/svg/integrations/4_Pasarelas_de_pago/14_ideal.svg";
import Payments_15 from "../../assets/svg/integrations/4_Pasarelas_de_pago/15_pci_proxy.svg";
import Payments_16 from "../../assets/svg/integrations/4_Pasarelas_de_pago/16_bitcoin.svg";



import Financing_1 from "../../assets/svg/integrations/4_1_Financing/13_klarna.svg";

import PMS_1 from "../../assets/svg/integrations/5_PMS/1_tesipro.svg";
import PMS_2 from "../../assets/svg/integrations/5_PMS/2_acigroup.svg";
import PMS_3 from "../../assets/svg/integrations/5_PMS/3_prestige.svg";
import PMS_4 from "../../assets/svg/integrations/5_PMS/4_tms_for_hotels.svg";
import PMS_5 from "../../assets/svg/integrations/5_PMS/5_infor.svg";
import PMS_6 from "../../assets/svg/integrations/5_PMS/6_sap.svg";
import PMS_7 from "../../assets/svg/integrations/5_PMS/7_engisoft.svg";
import PMS_8 from "../../assets/svg/integrations/5_PMS/8_icg_front_hotel.svg";
import PMS_9 from "../../assets/svg/integrations/5_PMS/9_tec-soft.svg";
import PMS_10 from "../../assets/svg/integrations/5_PMS/10_hotel_manager.svg";
import PMS_11 from "../../assets/svg/integrations/5_PMS/11_winhotel.svg";


import Analytics_1 from "../../assets/svg/integrations/6_Sistemas_de_análitica/1_google_analytics.svg";
import Analytics_2 from "../../assets/svg/integrations/6_Sistemas_de_análitica/2_google_ads_svg.svg";
import Analytics_3 from "../../assets/svg/integrations/6_Sistemas_de_análitica/3_google_tag_manager.svg";

import Compare_1 from "../../assets/svg/integrations/7_Widgets_de_comparación/1_the_hotels_network.svg";
import Compare_2 from "../../assets/svg/integrations/7_Widgets_de_comparación/2_triptease.svg";
import Compare_3 from "../../assets/svg/integrations/7_Widgets_de_comparación/3_123compare.me.svg";
import Compare_4 from "../../assets/svg/integrations/7_Widgets_de_comparación/4_hotel_champ.svg";

import Chat_1 from "../../assets/svg/integrations/8_Chatbots/1_quick_text.svg";
import Chat_2 from "../../assets/svg/integrations/8_Chatbots/2_asksuite.svg";
import Chat_3 from "../../assets/svg/integrations/8_Chatbots/3_hi_jiffy.svg";

import Call_Center_1 from "../../assets/svg/integrations/8.1_Call_Centers/1_thinkin.svg";
import Call_Center_2 from "../../assets/svg/integrations/8.1_Call_Centers/2_gimH.svg";

import Call_Center_Virtual_1 from "../../assets/svg/integrations/8.2_Call_Center_Virtual/1_bookline.svg";

import Insurance_1 from "../../assets/svg/integrations/9_Seguros_de_viaje/1_besafe.svg";
import Insurance_2 from "../../assets/svg/integrations/9_Seguros_de_viaje/2_flex_my_room.svg";

import Marketing_1 from "../../assets/svg/integrations/10_Marketing/1_salesforce.svg";
import Marketing_2 from "../../assets/svg/integrations/10_Marketing/2_fideltour.svg";
import Marketing_3 from "../../assets/svg/integrations/10_Marketing/3_pushtech.svg";
import Marketing_4 from "../../assets/svg/integrations/10_Marketing/4_sugarcrm.svg";
import Marketing_5 from "../../assets/svg/integrations/10_Marketing/5_hubspot.svg";

import RoomSelection_1 from "../../assets/svg/integrations/11_Room_Selection/1_hotelverse.svg";

// Components
import BreadCrumbs from "../../layout/breadcrumbs";
import { Helmet } from "react-helmet";

const messages = defineMessages({
  titleSEO: {
    id: "page.integrations.titleSEO",
    description: "Witbooking integrations",
    defaultMessage: "Integraciones Witbooking",
  },
  descriptionSEO: {
    id: "page.integrations.descriptionSEO",
    description: "Witbooking booking engine integrations with channel managers, meta search engines, payment methods, PMS, comparison widgets, chatbots",
    defaultMessage: "Integraciones motor de reservas Witbooking con channel managers, metabuscadores, métodos de pago, PMS, widgets de comparación, chatbots",
  },
  keywordSEO:{
    id: "page.integrations.keywordSEO",
    description: "Integrations, connectivity, channel managers, meta search engines, payment methods, PMS, comparison widgets, chatbots",
    defaultMessage: "Integraciones, conectividad, channel managers, metabuscadores, métodos pago, PMS, widgets de comparación, chatbots",
  },
  breadcrumb: {
    id: "page.integrations.breadcrumb",
    description: "page integrations breadcrumb",
    defaultMessage: "Integraciones",
  },
  menuChannel: {
    id: "page.integrations.menuChannel",
    description: "page menu option Channel Manager",
    defaultMessage: "Channel Managers",
  },
  menuMetas: {
    id: "page.integrations.menuMetas",
    description: "page menu option Metas",
    defaultMessage: "Metabuscadores",
  },
  menuMetamanagers: {
    id: "page.integrations.menuMetamanagers",
    description: "page menu option Metamanagers",
    defaultMessage: "Meta-managers",
  },
  menuPayments: {
    id: "page.integrations.menuPayments",
    description: "page menu option Payment Gateways",
    defaultMessage: "Pasarelas de pago",
  },
  menuFinancing: {
    id: "page.integrations.menuFinancing",
    description: "page menu option financing",
    defaultMessage: "Financiación",
  },
  menuPMS: {
    id: "page.integrations.menuPMS",
    description: "page menu option PMS",
    defaultMessage: "PMS",
  },
  menuAnalytics: {
    id: "page.integrations.menuAnalytics",
    description: "page menu option Analytics",
    defaultMessage: "Sistemas de analítica",
  },
  menuCompare: {
    id: "page.integrations.menuCompare",
    description: "page menu option Compare Widgets",
    defaultMessage: "Widgets de comparación",
  },
  menuChat: {
    id: "page.integrations.menuChat",
    description: "page menu option Chatbots",
    defaultMessage: "Chatbots",
  },
  menuCallCenters: {
    id: "page.integrations.menuCallCenters",
    description: "page menu option Call Centers",
    defaultMessage: "Call Centers",
  },
  menuVirtualCallCenter: {
    id: "page.integrations.menuVirtualCallCenter",
    description: "page menu option Virtual Call Center",
    defaultMessage: "Virtual call center (voice bot)",
  },
  menuInsurance: {
    id: "page.integrations.menuInsurance",
    description: "page menu option Travel Insurances",
    defaultMessage: "Seguros de viaje",
  },
  menuMarketing: {
    id: "page.integrations.menuMarketing",
    description: "page menu option Travel Insurances",
    defaultMessage: "Seguros de viaje",
  },
  menuRoomSelection: {
    id: "page.integrations.3DRoomSelection",
    description: "page menu option 3D Room Selection",
    defaultMessage: "Selección habitaciones 3D",
  },
  title: {
    id: "page.integrations.pageTitle",
    description: "page integrations title",
    defaultMessage: "Integraciones",
  },
  intro: {
    id: "page.integrations.pageIntro",
    description: "page integrations intro",
    defaultMessage:
      "Las diferentes integraciones del motor de Witbooking con channel managers, metabuscadores, meta-managers, pasarelas de pago, PMS y widgets de comparación te dan la libertad de escoger los partners que más te interesen para la venta directa.",
  },
  channelTitle: {
    id: "page.integrations.channelTitle",
    description: "Channel Managers title",
    defaultMessage: "Channel Managers",
  },
  channelText: {
    id: "page.integrations.channelText",
    description: "Channel Managers text",
    defaultMessage:
      "Los channel managers permiten al hotelero controlar su inventario de forma centralizada. El motor de reservas de Witbooking está integrado con: Siteminder, Yield Planet, Dingus, e-GDS, Icnea, Prestige Software, Parity Rate, Rate Tiger, Rate Gain y Synxis.",
  },
  metasTitle: {
    id: "page.integrations.metasTitle",
    description: "Metabuscadores title",
    defaultMessage: "Metabuscadores",
  },
  metasText: {
    id: "page.integrations.metasText",
    description: "Metabuscadores text",
    defaultMessage:
      "Los metabuscadores ayudan al viajero a comparar precios en los distintos canales de reserva. Con esta integraciones directas los hoteleros pueden publicitar su precio directo al huésped potencial. El motor de reservas de Witbooking está integrado con: Tripadvisor Instant Booking, Trivago y Google Hotel Ads.",
  },
  metamanagerTitle: {
    id: "page.integrations.metamanagerTitle",
    description: "Metamanagers title",
    defaultMessage: "Meta-managers",
  },
  metamanagerText: {
    id: "page.integrations.metamanagerText",
    description: "Metamanagers text",
    defaultMessage:
      "Los meta-managers son plataformas para la gestión integral de campañas en los metabuscadores (Google Hotel Ads, Tripadvisor, Trivago, Skyscanner, Kayak y Hotels Combined). El motor de reservas de Witbooking está integrado con: Whip (meta IO) y MyHotelShop.",
  },
  paymentsTitle: {
    id: "page.integrations.paymentsTitle",
    description: "Payment gateways title",
    defaultMessage: "Pasarelas de pago y financiación",
  },
  paymentsText: {
    id: "page.integrations.paymentsText",
    description: "Payment gateways text",
    defaultMessage:
      "Las pasarelas de pago permiten al hotelero cobrar depósitos en tiempo real y las penalizaciones (ej. no show) en diferido. El motor de reservas de Witbooking está integrado con: Stripe, Paypal, Redsys y Paycomet. También permite financiar las reservas con: Aplazame",
  },
  pmsTitle: {
    id: "page.integrations.pmsTitle",
    description: "PMS title",
    defaultMessage: "PMS",
  },
  pmsText: {
    id: "page.integrations.pmsText",
    description: "PMS text",
    defaultMessage:
      "Del inglés “Property Management Systems” son el sistema central de gestión de los negocios hoteleros. Esta integración permite al hotelero descargar automáticamente las reservas registradas en el motor hacia el PMS. El motor de reservas de Witbooking está integrado con: Engisoft, Winhotel, Prestige Software, TMS For hotels, Infor, SAP, Ulyses Cloud (Tesipro), ICG FrontHotel, Tec-Soft y Hotel Manager.",
  },
  analyticsTitle: {
    id: "page.integrations.analyticsTitle",
    description: "Analytics title",
    defaultMessage: "Sistemas de análitica",
  },
  analyticsText: {
    id: "page.integrations.analyticsText",
    description: "Analytics text",
    defaultMessage:
      "El tracking pixel permite monitorizar la actividad del motor en los principales sistemas de seguimiento web. El motor de reservas de Witbooking está integrado con: Google Analytics, Google Adwords y Google Tag Manager.",
  },
  compareTitle: {
    id: "page.integrations.compareTitle",
    description: "Compare Widgets title",
    defaultMessage: "Widgets de comparación",
  },
  compareText: {
    id: "page.integrations.compareText",
    description: "Compare Widgets text",
    defaultMessage:
      "Los widgets de comparación proporcionan comparativa de precios onmicanal en tiempo real y mensajería avanzada en el motor de reservas. El motor de reservas de Witbooking está integrado con: The Hotels Network, Triptease y 123Compare.me.",
  },
  chatTitle: {
    id: "page.integrations.chatTitle",
    description: "Chatbot title",
    defaultMessage: "Chatbots",
  },
  chatText: {
    id: "page.integrations.chatText",
    description: "Chatbot text",
    defaultMessage:
      "Los chatbots son un canal de comunicación con el cliente que mediante inteligencia artificial automatizan las comunicaciones más comunes (preguntas frecuentes) ahorrando tiempo al personal del hotel y esperas al huésped. El motor de reservas de Witbooking está integrado con: Quicktext y Hijiffy.",
  },
  insuranceTitle: {
    id: "page.integrations.insuranceTitle",
    description: "Travel Insurances title",
    defaultMessage: "Seguros de viaje",
  },
  insuranceText: {
    id: "page.integrations.insuranceText",
    description: "Travel Insurances text",
    defaultMessage: "Seguros de cancelación y asistencia en viaje: Flexmyroom",
  },
  marketingTitle: {
    id: "page.integrations.marketingTitle",
    description: "Travel Marketing title",
    defaultMessage: "Seguros de viaje",
  },
  marketingText: {
    id: "page.integrations.marketingText",
    description: "Travel Marketing text",
    defaultMessage: "Financiación",
  },
  roomSelectionTitle: {
    id: "page.integrations.3DRoomSelectionTitle",
    description: "3D Room Selection title",
    defaultMessage: "Seguros de viaje",
  },
  roomSelectionText: {
    id: "page.integrations.3DRoomSelectionText",
    description: "3D Room Selection text",
    defaultMessage: "Módulo de selección de habitaciones en una fotografía 3D interactiva. El motor de reservas de Witbooking está integrado con: Hotelverse.",
  },
  financingTitle: {
    id: "page.integrations.financingTitle",
    description: "Financing Title",
    defaultMessage: "Financiación",
  },
  financingText: {
    id: "page.integrations.financingText",
    description: "Financing text",
    defaultMessage: "El motor ofrece en el momento del pago la opción de pagar en diferentes plazos. El motor de reservas de Witbooking está integrado con: Klarna, Sequra",
  },
  callCenterText: {
    id: "page.integrations.callCenterText",
    description: "Call centers text",
    defaultMessage: "Para aquellos establecimientos o cadenas que contratan call centers externos para gestionar las reservas por email y teléfono. El motor de reservas de Witbooking está integrado con Thinkin y GIMH.",
  },
  callCenterTitle: {
    id: "page.integrations.callCenterTitle",
    description: "Call centers title",
    defaultMessage: "Call centers",
  },
  virtualCallCenterTitle: {
    id: "page.integrations.virtualCallCenterTitle",
    description: "Call center virtual Title",
    defaultMessage: "Call center virtual (voicebot)",
  },
  virtualCallCenterText: {
    id: "page.integrations.virtualCallCenterText",
    description: "Call center virtual text",
    defaultMessage: "Para la gestión automatizada de peticiones de reserva por teléfono que finalizan en el motor de reservas. El motor de reservas de Witbooking está integrado con Bookline.",
  },
});

export default function Integrations() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.titleSEO)}</title>
        <meta name="title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta name="description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta name="keywords" content={intl.formatMessage(messages.keywordSEO)}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://metatags.io/"/>
        <meta property="og:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="og:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://metatags.io/"/>
        <meta property="twitter:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="twitter:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>
      </Helmet>
      <BreadCrumbs
        breadcrumbText={intl.formatMessage(messages.breadcrumb)}
        pageTitle={intl.formatMessage(messages.title)}
        pageIntro={intl.formatMessage(messages.intro)}
        classHeader="var-menu-space"
      />

      <div className="inlinks-menu-detector">
        <aside className="inlinks-menu">
          <div className="wrap-inlinks-menu">
            <ul className="inlinks-menu-list">
              <li
                className="inlinks-menu-element active js-scrollto"
                data-scroll_to="#channel-managers"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuChannel} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#metabuscadores"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuMetas} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#meta-managers"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuMetamanagers} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#pasarelas-de-pago"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuPayments} />
                  </p>
                </div>
              </li>
              <li
                  className="inlinks-menu-element js-scrollto"
                  data-scroll_to="#financiacion"
                  data-scroll_spacer="130"
                  role="button"
                  tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuFinancing} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#pms"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuPMS} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#sistemas-de-analitica"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuAnalytics} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#widgets-de-comparacion"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuCompare} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#chatbots"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuChat} />
                  </p>
                </div>
              </li>
              <li
                  className="inlinks-menu-element js-scrollto"
                  data-scroll_to="#callCenters"
                  data-scroll_spacer="130"
                  role="button"
                  tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuCallCenters} />
                  </p>
                </div>
              </li>
              <li
                  className="inlinks-menu-element js-scrollto"
                  data-scroll_to="#virtualCallCenter"
                  data-scroll_spacer="130"
                  role="button"
                  tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuVirtualCallCenter} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#seguros-de-viaje"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuInsurance} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#marketing"
                data-scroll_spacer="130"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuMarketing} />
                  </p>
                </div>
              </li>
              <li
                  className="inlinks-menu-element js-scrollto"
                  data-scroll_to="#3DRoomSelection"
                  data-scroll_spacer="130"
                  role="button"
                  tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuRoomSelection} />
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </aside>
      </div>

      <section
        id="channel-managers"
        className="graphics-module js-point-detector"
      >
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.channelTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.channelText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_3}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                     /* style={{width: "150px !important"}}*/
                      className="graphics-list-img"
                      data-lazyimg={Channel_3_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Channel_3_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_4}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_5}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_6}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_7}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_8}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_9}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_10}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Channel_11}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="metabuscadores"
        className="graphics-module js-point-detector"
      >
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.metasTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.metasText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Metas_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Metas_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Metas_3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="meta-managers" className="graphics-module js-point-detector">
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.metamanagerTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.metamanagerText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Metamanager_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Metamanager_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Metamanager_3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="pasarelas-de-pago"
        className="graphics-module js-point-detector"
      >
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.paymentsTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.paymentsText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Payments_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Payments_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Payments_3}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_4}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_5}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_6}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_7}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_8}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_9}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_10}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_11}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_13}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_14}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Payments_15}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
          id="financiacion"
          className="graphics-module js-point-detector"
      >
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.financingTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.financingText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Financing_1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pms" className="graphics-module js-point-detector">
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.pmsTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.pmsText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_3}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_4}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_5}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_6}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_7}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_8}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_9}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_10}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={PMS_11}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="sistemas-de-analitica"
        className="graphics-module js-point-detector"
      >
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.analyticsTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.analyticsText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Analytics_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Analytics_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Analytics_3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="widgets-de-comparacion"
        className="graphics-module js-point-detector"
      >
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.compareTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.compareText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Compare_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Compare_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Compare_3}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Compare_4}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="callCenters" className="graphics-module js-point-detector">
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.callCenterTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.callCenterText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Call_Center_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Call_Center_2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="virtualCallCenter" className="graphics-module js-point-detector">
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.virtualCallCenterTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.virtualCallCenterText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Call_Center_Virtual_1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="chatbots" className="graphics-module js-point-detector">
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.chatTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.chatText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Chat_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Chat_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Chat_3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="seguros-de-viaje"
        className="graphics-module js-point-detector"
      >
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.insuranceTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.insuranceText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Insurance_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Insurance_2}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="marketing" className="graphics-module js-point-detector">
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.marketingTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.marketingText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                    src=""
                    alt=""
                    className="graphics-list-img"
                    data-lazyimg={Marketing_1}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Marketing_2}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Marketing_3}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Marketing_4}
                  />
                </div>
              </div>
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={Marketing_5}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="3DRoomSelection" className="graphics-module js-point-detector">
        <div className="wrap-graphics-module">
          <div className="graphics-element-info">
            <div className="wrap-graphics-info">
              <h2 className="graphics-info-title">
                <FormattedMessage {...messages.roomSelectionTitle} />
              </h2>
              <p className="graphics-info-text">
                <FormattedMessage {...messages.roomSelectionText} />
              </p>
            </div>
          </div>
          <div className="graphics-element-list">
            <div className="wrap-graphics-list">
              <div className="graphics-list-element">
                <div className="wrap-list-element">
                  <img
                      src=""
                      alt=""
                      className="graphics-list-img"
                      data-lazyimg={RoomSelection_1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
