import React, { useEffect, useState } from "react";

// Const
const RECAPTCHA_SITEKEY = process.env.REACT_APP_RECAPTCHA_SITEKEY;
const CONTAINER_ID = "reCaptcha";

function ReCaptcha({ onSuccess }) {
  const [readyCheck, setReadyCheck] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isRender, setIsRender] = useState(false);

  // INIT
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.google.com/recaptcha/api.js";
    document.body.appendChild(script);

    const id = setInterval(() => {
      if (
        typeof window !== "undefined" &&
        typeof window.grecaptcha !== "undefined" &&
        typeof window.grecaptcha.render === "function"
      ) {
        setIsReady(true);
      }
    }, 500);
    setReadyCheck(id);
  }, []);

  useEffect(() => {
    if (isReady && !isRender) {
      clearInterval(readyCheck);
      setIsRender(true);
      window.grecaptcha.render(CONTAINER_ID, {
        sitekey: RECAPTCHA_SITEKEY,
        callback: onSuccess,
      });
    }
  }, [readyCheck, isReady, onSuccess]);

  return <div id={CONTAINER_ID} />;
}

export default ReCaptcha;
