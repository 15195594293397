import React from "react";
import {Helmet} from "react-helmet";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";

// Assets
import Shop from "../../assets/img/modules/1_Tienda online_1636x1341.png";
import Payment from "../../assets/img/modules/2_Pagos smart_1636x1341.png";
import Loyalty from "../../assets/img/modules/3_Sistema fidelizacion_1636x1341.png";

import BreadCrumbs from "../../layout/breadcrumbs";

const messages = defineMessages({
  titleSEO: {
    id: "page.modules.titleSEO",
    description: "Add-ons Witbooking booking engine",
    defaultMessage: "Módulos motor de reservas Witbooking",
  },
  descriptionSEO: {
    id: "page.modules.descriptionSEO",
    description: "Features that complement the Witbooking booking engine",
    defaultMessage: "Funcionalidades que complementan el motor de reservas de Witbooking",
  },
  keywordSEO: {
    id: "page.modules.keywordSEO",
    description: "Add-ons Witbooking booking engine",
    defaultMessage: "Módulos, metabuscadores, tienda online, cheque regalo, sistema fidelización, smart payments, tokenizado, PSD2, pasarela de pago",
  },
  breadcrumb: {
    id: "page.modules.breadcrumb",
    description: "page modules breadcrumb",
    defaultMessage: "Módulos",
  },
  menuShop: {
    id: "page.modules.menuShop",
    description: "menu modules option",
    defaultMessage: "Tienda online",
  },
  menuPayment: {
    id: "page.modules.menuPayment",
    description: "menu modules option",
    defaultMessage: "Pagos Smart",
  },
  menuLoyalty: {
    id: "page.modules.menuLoyalty",
    description: "menu modules option",
    defaultMessage: "Sistema fidelización",
  },
  menuWeb: {
    id: "page.modules.menuWeb",
    description: "menu modules option",
    defaultMessage: "Web Express",
  },
  title: {
    id: "page.modules.title",
    description: "page modules title",
    defaultMessage: "Módulos",
  },
  intro: {
    id: "page.modules.intro",
    description: "page modules intro",
    defaultMessage:
      "Para optimizar la venta directa de tu establecimiento te ofrecemos las siguientes funcionalidades que complementan nuestro motor de reservas.",
  },
  shopTitle: {
    id: "page.modules.shopTitle",
    description: "Shop title",
    defaultMessage: "Tienda online",
  },
  shopIntro: {
    id: "page.modules.shopIntro",
    description: "Shop intro",
    defaultMessage:
      "Ecommerce para poder vender experiencias en forma de cheque regalo con fecha de disfrute abierta, así como también productos y servicios sin alojamiento asociado.",
  },
  shopBtn: {
    id: "page.modules.shopBtn",
    description: "Shop button",
    defaultMessage: "Ver tienda online",
  },
  shopSectionOneTitle: {
    id: "page.modules.shopSectionOneTitle",
    description: "shop section one title",
    defaultMessage: "Automatización de compra y canje",
  },
  shopSectionOneText: {
    id: "page.modules.shopSectionOneText",
    description: "shop section one text",
    defaultMessage:
      "Tanto la venta como el canje se producen online, liberando al personal del hotel de la gestión. Este tipo de proceso de compra son esenciales para las nuevas generaciones que se sienten más cómodos con las experiencias desatendidas.",
  },
  shopSectionTwoTitle: {
    id: "page.modules.shopSectionTwoTitle",
    description: "shop section two title",
    defaultMessage: "Incremento precio medio y motivación",
  },
  shopSectionTwoText: {
    id: "page.modules.shopSectionTwoText",
    description: "shop section two text",
    defaultMessage:
      "Las experiencias además de aumentar el precio medio (ej. golf, esquí, restauración, SPA) dotan de sentido a la pernoctación, aumentando así la conversión de visitantes a reservas de nuestro canal propio.",
  },
  shopSectionThreeTitle: {
    id: "page.modules.shopSectionThreeTitle",
    description: "shop section three title",
    defaultMessage: "Venta de productos y servicios sin pernoctación",
  },
  shopSectionThreeText: {
    id: "page.modules.shopSectionThreeText",
    description: "shop section three text",
    defaultMessage:
      "Es una gran forma de comercializar productos y servicios a los clientes reforzando, aumentando el ticket medio y reforzando la experiencia incluso más allá de la estancia.",
  },
  shopSectionFourTitle: {
    id: "page.modules.shopSectionFourTitle",
    description: "shop section four title",
    defaultMessage: "Cliente local, negocio incremental",
  },
  shopSectionFourText: {
    id: "page.modules.shopSectionFourText",
    description: "shop section four text",
    defaultMessage:
      "En la mayoría de casos las experiencias son disfrutadas por clientes locales, por tanto abre las puertas a un nuevo tipo de cliente, el de cercanía aumentando así los ingresos totales del  establecimiento.",
  },
  shopSectionFiveTitle: {
    id: "page.modules.shopSectionFiveTitle",
    description: "shop section five title",
    defaultMessage: "Anticipación de ingresos y “no canje”",
  },
  shopSectionFiveText: {
    id: "page.modules.shopSectionFiveText",
    description: "shop section five text",
    defaultMessage:
      "Los ingresos se producen en el momento de la venta del cheque regalo, adicionalmente se calcula que un 20% de las experiencias jamás se canjearán, otorgando al hotel unos ingresos adicionales sin coste asociado.",
  },
  paymentTitle: {
    id: "page.modules.paymentTitle",
    description: "payment title",
    defaultMessage: "Pagos Smart",
  },
  paymentIntro: {
    id: "page.modules.paymentIntro",
    description: "payment intro",
    defaultMessage:
      "Una de las formas más efectivas para captar clientes a través del canal directo es mediante formas, condiciones y tiempos de pago más convenientes que los que  ofrecen los canales de comercialización intermediados. Este módulo ofrece un sin fin de funcionalidades para incentivar la reserva directa y reducir el fraude.",
  },
  paymentSectionOneTitle: {
    id: "page.modules.paymentSectionOneTitle",
    description: "payment section one title",
    defaultMessage: "Tokenización",
  },
  paymentSectionOneText: {
    id: "page.modules.paymentSectionOneText",
    description: "payment section one text",
    defaultMessage:
      "Encriptado de datos de las tarjetas de crédito sin perder la capacidad de operar con ellas (cargos, devoluciones) evitando el fraude interno/externo, las multas de las marcas (VISA, Mastercard), los errores humanos en el tecleo y costes administrativos de procesar los cargos manualmente.",
  },
  paymentSectionTwoTitle: {
    id: "page.modules.paymentSectionTwoTitle",
    description: "payment section two title",
    defaultMessage: "Pagos integrados en el motor",
  },
  paymentSectionTwoText: {
    id: "page.modules.paymentSectionTwoText",
    description: "payment section two text",
    defaultMessage:
      "Una de las grandes ventajas de este módulo es que el pago como la autenticación (solicitud de coordenada, pin o datos biométricos) se producen dentro del sistema de reservas, evitando la redirección a sistemas externos que reduce la conversión enormemente.",
  },
  paymentSectionThreeTitle: {
    id: "page.modules.paymentSectionThreeTitle",
    description: "payment section three title",
    defaultMessage: "Preautorizaciones invisibles",
  },
  paymentSectionThreeText: {
    id: "page.modules.paymentSectionThreeText",
    description: "payment section three text",
    defaultMessage:
      "Lanzar una operación de preautorización cuando una tarjeta de crédito ingresa en el sistema para evitar tarjetas fraudulentas, al anularse inmediatamente el huésped no verá la operación en su extracto. Si la pre-autorización es negativa se solicita un medio de pago alternativo.",
  },
  paymentSectionFourTitle: {
    id: "page.modules.paymentSectionFourTitle",
    description: "payment section four title",
    defaultMessage: "Medios alternativos",
  },
  paymentSectionFourText: {
    id: "page.modules.paymentSectionFourText",
    description: "payment section four text",
    defaultMessage:
      "Podrá ofrecer medios de pago alternativos en caso de denegación o fallo en la comunicación (ej. Stripe o PayPal).",
  },
  paymentSectionFiveTitle: {
    id: "page.modules.paymentSectionFiveTitle",
    description: "payment section five title",
    defaultMessage: "Métodos de pago condicionados",
  },
  paymentSectionFiveText: {
    id: "page.modules.paymentSectionFiveText",
    description: "payment section five text",
    defaultMessage:
      "Permite ofrecer determinados medios de pago según la geolocalización del usuario, el tipo de dispositivo de conexión, la antelación de la reserva y/o el importe total.",
  },
  paymentSectionSixTitle: {
    id: "page.modules.paymentSectionSixTitle",
    description: "payment section six title",
    defaultMessage: "Pre-reservas con bloqueo de cupo",
  },
  paymentSectionSixText: {
    id: "page.modules.paymentSectionSixText",
    description: "payment section six text",
    defaultMessage:
      "Permite al hotelero establecer unas reglas (fechas, antelación, geolocalización, importe) por las cuales se habilita la reserva con pago diferido (Ej. 24h) permitiendo al huésped organizar su viaje sin estrés (ej. comprar su vuelo, consultar con allegados). Durante este plazo el usuario podrá pagar mediante el enlace enviado junto al email de reserva, si pasado el plazo no se consuma el pago la reserva se cancelará automáticamente liberando el cupo bloqueado. Esta funcionalidad es muy interesante para enviar pre-reservas a los huéspedes desde el departamento de reservas o call center.",
  },
  paymentSectionSevenTitle: {
    id: "page.modules.paymentSectionSevenTitle",
    description: "payment section seven title",
    defaultMessage: "Cobros diferidos seguros (MIT)",
  },
  paymentSectionSevenText: {
    id: "page.modules.paymentSectionSevenText",
    description: "payment section seven text",
    defaultMessage:
      "Esta nueva funcionalidad asociada a la PSD2, permite pagos futuros seguros (no-show, cancelaciones fuera de plazo, pagos parciales) con la operativa de pagos recurrentes que contempla la nueva normativa de pagos europea.",
  },
  paymentSectionEightTitle: {
    id: "page.modules.paymentSectionEightTitle",
    description: "payment section eight title",
    defaultMessage: "Reservas sin pago",
  },
  paymentSectionEightText: {
    id: "page.modules.paymentSectionEightText",
    description: "payment section eight text",
    defaultMessage:
      "Este método de (no) pago consiste en aceptar reservas sin solicitar un medio de pago, y por tanto con máxima conversión, la idea detrás de esta fórmula es habilitarla para periodos de baja ocupación donde los no shows tienen un efecto económico prácticamente nulo.",
  },
  paymentSectionNineTitle: {
    id: "page.modules.paymentSectionNineTitle",
    description: "payment section nine title",
    defaultMessage: "Reembolso mediante cheque regalo (gift-card)",
  },
  paymentSectionNineText: {
    id: "page.modules.paymentSectionNineText",
    description: "payment section nine text",
    defaultMessage:
      "Permite crear tarifa no reembolsable “light” donde, en caso de cancelación el cliente recibe un reembolso en forma de código de saldo (gift card) para futuras reservas logrando así generar una disparidad a favor del canal directo.",
  },
  loyaltyTitle: {
    id: "page.modules.loyaltyTitle",
    description: "loyalty title",
    defaultMessage: "Sistema fidelización",
  },
  loyaltyIntro: {
    id: "page.modules.loyaltyIntro",
    description: "loyalty intro",
    defaultMessage:
      "Hoy día es esencial conocer al cliente para personalizar nuestra oferta (productos, tarifas), para ello es capital contar con una base de datos depurada y las herramientas para maximizar las ventas tanto reactivamente como proactivamente. Un sistema de fidelización con área privada en nuestra web es una forma sencilla de iniciar la transición del cliente ocasional al cliente repetitivo.",
  },
  loyaltySectionOneTitle: {
    id: "page.modules.loyaltySectionOneTitle",
    description: "loyalty section one title",
    defaultMessage: "Registro y login",
  },
  loyaltySectionOneText: {
    id: "page.modules.loyaltySectionOneText",
    description: "loyalty section one text",
    defaultMessage:
      "Permite al usuario registrarse/identificarse mediante formulario o bien con los widgets de Google o Facebook para agilizar el proceso.",
  },
  loyaltySectionTwoTitle: {
    id: "page.modules.loyaltySectionTwoTitle",
    description: "loyalty section two title",
    defaultMessage: "Área privada",
  },
  loyaltySectionTwoText: {
    id: "page.modules.loyaltySectionTwoText",
    description: "loyalty section two text",
    defaultMessage:
      "Con su historial de reservas, la posibilidad de  modificación/cancelar las reservas vivas, la gestión de sus datos personales y el nivel de membresía que tiene (ej. Platinum).",
  },
  loyaltySectionThreeTitle: {
    id: "page.modules.loyaltySectionThreeTitle",
    description: "loyalty section three title",
    defaultMessage: "Niveles de membresía y ventajas asociadas",
  },
  loyaltySectionThreeText: {
    id: "page.modules.loyaltySectionThreeText",
    description: "loyalty section three text",
    defaultMessage:
      "Permite crear tantos niveles de membresía como se desee aplicando reglas de acceso (subscripción al newsletter, número de reservas, importe reservas) y sus beneficios asociados: tarifas, descuentos y/o regalos exclusivos.",
  },
  loyaltySectionFourTitle: {
    id: "page.modules.loyaltySectionFourTitle",
    description: "loyalty section four title",
    defaultMessage: "Segmentación de clientes",
  },
  loyaltySectionFourText: {
    id: "page.modules.loyaltySectionFourText",
    description: "loyalty section four text",
    defaultMessage:
      "Permite segmentar los clientes por múltiples criterios: procedencia, idioma, gasto total, gasto por reserva, antelación, estancia media y/o productos contratados para su posterior uso analitico y para impactarles a través de campañas puntuales.",
  },
  loyaltySectionFiveTitle: {
    id: "page.modules.loyaltySectionFiveTitle",
    description: "loyalty section five title",
    defaultMessage: "Campañas puntuales",
  },
  loyaltySectionFiveText: {
    id: "page.modules.loyaltySectionFiveText",
    description: "loyalty section five text",
    defaultMessage:
      "Permite crear campañas específicas (ej. Promoción Halloween) a determinados niveles de membresía o segmentos mediante emailings con mensajes personalizados y códigos promocionales de uso único.",
  },
  loyaltySectionSixTitle: {
    id: "page.modules.loyaltySectionSixTitle",
    description: "loyalty section six title",
    defaultMessage: "Check-out express",
  },
  loyaltySectionSixText: {
    id: "page.modules.loyaltySectionSixText",
    description: "loyalty section six text",
    defaultMessage:
      "El sistema permite un proceso de reservas rápido al evitar al huésped tener que rellenar sus datos personales e incluso utilizar una tarjeta de crédito guardada en el sistema.",
  },
  loyaltySectionSevenTitle: {
    id: "page.modules.loyaltySectionSevenTitle",
    description: "loyalty section seven title",
    defaultMessage: "Conexión a un sistema externo",
  },
  loyaltySectionSevenText: {
    id: "page.modules.loyaltySectionSevenText",
    description: "loyalty section seven text",
    defaultMessage:
      "Para aquellos establecimientos que ya disponen de un sistema de fidelización o área privada en su web Witbooker es capaz de recibir los datos del cliente para un checkout rápido y sus beneficios como miembro.",
  },
  webTitle: {
    id: "page.modules.webTitle",
    description: "web title",
    defaultMessage: "Web express",
  },
  webIntro: {
    id: "page.modules.webIntro",
    description: "web intro",
    defaultMessage:
      "Plantilla de página web para aquellos establecimientos que precisan de una solución rápida, económica y funcional. Esta solución está recomendada para establecimientos pequeños con presupuesto reducido y/o como solución temporal mientras se realiza un proyecto a medida.",
  },
  webBtn: {
    id: "page.modules.webBtn",
    description: "web button",
    defaultMessage: "Web express",
  },
  webSectionOneTitle: {
    id: "page.modules.webSectionOneTitle",
    description: "web section one title",
    defaultMessage: "Rápido y muy asequible",
  },
  webSectionOneText: {
    id: "page.modules.webSectionOneText",
    description: "web section one text",
    defaultMessage:
      "Listo en 2 días laborables y con una inversión inicial muy reducida.",
  },
  webSectionTwoTitle: {
    id: "page.modules.webSectionTwoTitle",
    description: "web section two title",
    defaultMessage: "Responsivo",
  },
  webSectionTwoText: {
    id: "page.modules.webSectionTwoText",
    description: "web section two text",
    defaultMessage:
      "Se adapta a todos los dispositivos (escritorio, tablets y smartphones) para una óptima experiencia de navegación.",
  },
  webSectionThreeTitle: {
    id: "page.modules.webSectionThreeTitle",
    description: "web section three title",
    defaultMessage: "Personalizable",
  },
  webSectionThreeText: {
    id: "page.modules.webSectionThreeText",
    description: "web section three text",
    defaultMessage:
      "Permite adaptar la estética de la página a la imagen corporativa así como crear tantas páginas y secciones web como se requiera.",
  },
  webSectionFourTitle: {
    id: "page.modules.webSectionFourTitle",
    description: "web section four title",
    defaultMessage: "Auto-gestionable",
  },
  webSectionFourText: {
    id: "page.modules.webSectionFourText",
    description: "web section four text",
    defaultMessage:
      "Total y fácil autogestión de los contenidos y secciones desde el gestor de contenidos del panel de control.",
  },
  webSectionFiveTitle: {
    id: "page.modules.webSectionFiveTitle",
    description: "web section five title",
    defaultMessage: "Actualizaciones automáticas",
  },
  webSectionFiveText: {
    id: "page.modules.webSectionFiveText",
    description: "web section five text",
    defaultMessage:
      "Actualización de versiones automáticas para estar al día sin necesidad de invertir ni preocuparse de nada.",
  },
  webSectionSixTitle: {
    id: "page.modules.webSectionSixTitle",
    description: "web section six title",
    defaultMessage: "Visual",
  },
  webSectionSixText: {
    id: "page.modules.webSectionSixText",
    description: "web section six text",
    defaultMessage:
      "Enfocado a comunicar con imágenes y videos de los espacios y el entorno para evitar el exceso de textos, y lograr así una comunicación más efectiva.",
  },
  webSectionSevenTitle: {
    id: "page.modules.webSectionSevenTitle",
    description: "web section seven title",
    defaultMessage: "Indexación en buscadores (SEO)",
  },
  webSectionSevenText: {
    id: "page.modules.webSectionSevenText",
    description: "web section seven text",
    defaultMessage:
      "El panel de control permite gestionar el posicionamiento en buscadores para un óptimo posicionamiento en los principales buscadores.",
  },
  webSectionEightTitle: {
    id: "page.modules.webSectionEightTitle",
    description: "web section eight title",
    defaultMessage: "Motor de reserva integrado",
  },
  webSectionEightText: {
    id: "page.modules.webSectionEightText",
    description: "web section eight text",
    defaultMessage:
      "Se integra de forma nativa con Witbooker para una experiencia web totalmente homogénea.",
  },
});

export default function Modules() {
  const intl = useIntl();

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(messages.titleSEO)}</title>
        <meta name="title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta name="description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta name="keywords" content={intl.formatMessage(messages.keywordSEO)}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://metatags.io/"/>
        <meta property="og:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="og:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="og:image"
              content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://metatags.io/"/>
        <meta property="twitter:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="twitter:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="twitter:image"
              content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>
      </Helmet>
      <BreadCrumbs
        breadcrumbText={intl.formatMessage(messages.breadcrumb)}
        pageTitle={intl.formatMessage(messages.title)}
        pageIntro={intl.formatMessage(messages.intro)}
        classHeader="var-menu-space"
      />
      <div className="inlinks-menu-detector">
        <aside className="inlinks-menu">
          <div className="wrap-inlinks-menu">
            <ul className="inlinks-menu-list">
              <li
                className="inlinks-menu-element active js-scrollto"
                data-scroll_to="#tienda-online"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuShop} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#pagos-smart"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuPayment} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#sistema-fidelizacion"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuLoyalty} />
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </aside>
      </div>
      <section
        id="tienda-online"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-img"
                  data-lazyimg={Shop}
                />
              </div>
              <div className="details-multimedia-btn btn-corp">
                <a
                  rel="noreferrer"
                  href="https://store.hotelperalada.com/index.php?id_lang=1"
                  target="_blank"
                  className="btn-corp-text"
                >
                  <FormattedMessage {...messages.shopBtn} />
                </a>
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.shopTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.shopIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.shopSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.shopSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.shopSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.shopSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.shopSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.shopSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.shopSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.shopSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.shopSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.shopSectionFiveText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="pagos-smart"
        className="gallery-details js-point-detector var-info-right"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-img"
                  data-lazyimg={Payment}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.paymentTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionFiveText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionSixTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionSixText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionSevenTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionSevenText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionEightTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionEightText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionNineTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionNineText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="sistema-fidelizacion"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-img"
                  data-lazyimg={Loyalty}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.loyaltyTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltyIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.loyaltySectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltySectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.loyaltySectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltySectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.loyaltySectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltySectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.loyaltySectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltySectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.loyaltySectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltySectionFiveText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.loyaltySectionSixTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltySectionSixText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.loyaltySectionSevenTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.loyaltySectionSevenText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
