import React, { useMemo } from "react";
import { useLocaleProvider } from "../context/localeContext";

export default function BreadCrumbs({
  breadcrumbText,
  pageTitle,
  pageIntro,
  classHeader,
}) {
  const localeProvider = useLocaleProvider();
  const isSelectedEnglish = useMemo(
    () => localeProvider.currentLocale === "en-US",
    [localeProvider]
  );

  return (
    <section className={`intro-header ${classHeader}`}>
      <div className="big-wrapper">
        <div className="wrap-intro-header">
          <div className="intro-header-breadcrumbs">
            <div
              className="wrap-header-breadcrumbs"
              itemScope
              itemType="http://schema.org/BreadcrumbList"
            >
              <a
                href="/"
                className="header-breadcrumbs-element"
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                Home
              </a>
              <a
                href={`${
                  isSelectedEnglish ? "/booking-engine" : "/motor-de-reservas"
                }`}
                className="header-breadcrumbs-element active"
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                {breadcrumbText}
              </a>
            </div>
          </div>
          <h1 className="intro-header-title">{pageTitle}</h1>
          <p className="intro-header-text">{pageIntro}</p>
        </div>
      </div>
    </section>
  );
}
