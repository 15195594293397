import React from "react";
import "./App.css";
import "./styles/main.scss";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {IntlProvider} from "react-intl";
import messagesEn from "./translations/en.json";
import messagesEs from "./translations/es.json";
import {LocaleProvider} from "./context/localeContext";

// Components
import Home from "./pages/home";
import Layout from "./layout/layout";
import Contact from "./pages/contact";
import Pricing from "./pages/pricing";
import Modules from "./pages/modules";
import Integrations from "./pages/integrations";
import Legal from "./pages/legal";
import Motor from "./pages/motor";
import Policy from "./pages/policy";
import Cookies from "./pages/cookies";

function App() {
  const messages = {
    "en-US": messagesEn,
    "es-ES": messagesEs,
  };
  return (
    <Router>
      <LocaleProvider>
        {(locale) => (
          <IntlProvider
            locale={locale}
            messages={messages[locale] ? messages[locale] : messages["es-ES"]}
            defaultLocale="es-ES"
          >
            <Switch>
              <Route exact path="/">
                <Layout isHome>
                  <Home/>
                </Layout>
              </Route>
              <Route exact path={["/contact", "/contacto"]}>
                <Layout idSpacer="half-endpoint">
                  <Contact locale={locale}/>
                </Layout>
              </Route>
              <Route exact path={["/precios", "/rates"]}>
                <Layout>
                  <Pricing/>
                </Layout>
              </Route>
              <Route exact path={["/add-on", "/modulos"]}>
                <Layout>
                  <Modules/>
                </Layout>
              </Route>
              <Route exact path={["/integrations", "/integraciones"]}>
                <Layout>
                  <Integrations/>
                </Layout>
              </Route>
              <Route exact path="/legal">
                <Layout idSpacer="half-endpoint">
                  <Legal/>
                </Layout>
              </Route>
              <Route exact path="/policy">
                <Layout idSpacer="half-endpoint">
                  <Policy/>
                </Layout>
              </Route>
              <Route exact path="/cookies">
                <Layout idSpacer="half-endpoint">
                  <Cookies/>
                </Layout>
              </Route>
              <Route exact path={["/motor-de-reservas", "/booking-engine"]}>
                <Layout>
                  <Motor/>
                </Layout>
              </Route>
            </Switch>
          </IntlProvider>
        )}
      </LocaleProvider>
    </Router>
  );
}

export default App;
