import React, { useCallback, useContext, useState } from "react";

const localeKey = "locale";
const savedLanguage =
  window.localStorage.getItem(localeKey) || navigator.language;
const LanguageContext = React.createContext({
  currentLocale: savedLanguage,
  setLocale: () => {},
});
export function LocaleProvider(props) {
  const [lang, setLang] = useState(savedLanguage);

  const setLanguage = useCallback((newLocale) => {
    window.localStorage.setItem(localeKey, newLocale);
    setLang(newLocale);
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        setLocale: setLanguage,
        currentLocale: lang,
      }}
    >
      {props.children(lang)}
    </LanguageContext.Provider>
  );
}

export function useLocaleProvider() {
  return useContext(LanguageContext);
}

export function languageFromLocaleString(localeString) {
  const pieces = localeString.split("-");
  return pieces[0];
}
