export const planTypes = {
  WITBOOKER: "witbooker",
  WITBOOKER_PLUS: "witbookerPlus",
  EMPTY: "empty"
}

export const rates = {
  EMPTY: "empty",
  ALL: "all",
  FLAT: "flat",
  MIXED: "mixed",
  VARIABLE: "variable"
}

export const units = {
  S: "S",
  M: "M",
  L: "L",
  XL: "XL",
  XXL: "XXL"
}

export const RATE_EMPTY = "rateEmpty";
export const UNIT_EMPTY = "unitEmpty";

export const rateFilters = [
  {
    key: RATE_EMPTY,
    name: "rateFiltersEmpty"
  },
  {
    key: "all",
    name: "rateFiltersAll"
  },
  {
    key: "flat",
    name: "rateFiltersFlat"
  },
  {
    key: "mixed",
    name: "rateFiltersMixed"
  },
  {
    key: "variable",
    name: "rateFiltersVariable"
  }
]

export const unitSizes = [
  {
    key: UNIT_EMPTY,
    name: "unitEmpty"
  },
  {
    key: "S",
    name: "unitSizeS"
  },
  {
    key: "M",
    name: "unitSizeM"
  },
  {
    key: "L",
    name: "unitSizeL"
  },
  {
    key: "XL",
    name: "unitSizeXL"
  },
  {
    key: "XXL",
    name: "unitSizeXXL"
  }
]

export const pricingValues = {
  [units.S]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "153",
        annualWebSaleCap: "125.000",
      },
      mixedRate: {
        variable: "0.6",
        fixedFee: "109",
      },
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "48",
      }
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "204",
        annualWebSaleCap: "125.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "145",
      },
      variableRate: {
        variable: "2",
        minimumPerMonth: "96",
      }
    }
  },
  [units.M]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "239",
        annualWebSaleCap: "250.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "151",
      },
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "63",
      }
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "319",
        annualWebSaleCap: "250.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "201",
      },
      variableRate: {
        variable: "2",
        minimumPerMonth: "126",
      }
    }
  },
  [units.L]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "357",
        annualWebSaleCap: "500.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "182",
      },
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "73",
      }
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "476",
        annualWebSaleCap: "500.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "243",
      },
      variableRate: {
        variable: "2",
        minimumPerMonth: "146",
      }
    }
  },
  [units.XL]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "478",
        annualWebSaleCap: "750.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "215",
      },
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "87",
      }
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "637",
        annualWebSaleCap: "750.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "287",
      },
      variableRate: {
        variable: "2",
        minimumPerMonth: "174",
      }
    }
  },
  [units.XXL]: {
    [planTypes.WITBOOKER]: {
      flatRate: {
        fixedFee: "734",
        annualWebSaleCap: "1.200.000",
      },
      mixedRate: {
        variable: "0.60",
        fixedFee: "314",
      },
      variableRate: {
        variable: "1.50",
        minimumPerMonth: "128",
      }
    },
    [planTypes.WITBOOKER_PLUS]: {
      flatRate: {
        fixedFee: "979",
        annualWebSaleCap: "1.200.000",
      },
      mixedRate: {
        variable: "0.80",
        fixedFee: "419",
      },
      variableRate: {
        variable: "2",
        minimumPerMonth: "256",
      }
    }
  }
};
