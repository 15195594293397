import React from "react";

// Assets
import Logo from "../assets/svg/logo.svg";

// Components
import Footer from "./footer";
import InlinksBanner from "./inlinksBanner";
import Nav from "./nav";

export default function Layout({
                                 isHome,
                                 children,
                                 idSpacer,
                               }) {
  return (
    <>
      <Nav/>
      {isHome ? (
        <>{children}</>
      ) : (
        <div className="intern">
          <div className="responsive-header">
            <a href="/">
              <div className="wrap-responsive-header">
                <img src={Logo} alt="logo" className="responsive-header-logo"/>
              </div>
            </a>
          </div>
          {children}
          <div id={idSpacer} className="spacer"></div>
        </div>
      )}
      <InlinksBanner/>
      <Footer/>
    </>
  );
}
