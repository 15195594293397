import countries from "i18n-iso-countries";
import en from "date-fns/locale/en-GB";
import es from "date-fns/locale/es";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export const locales = {
  en,
  es,
};
// Our API does not recognize Kosovo as a country ¯\_(ツ)_/¯
export const countryCodes = Object.keys(countries.getAlpha2Codes()).filter(
  (it) => it !== "XK"
);

const enList = countryCodes.map((it) => ({
  label: `${countries.getName(it, "en", { select: "official" })}`,
  value: it,
}));

const esList = countryCodes.map((it) => ({
  label: `${countries.getName(it, "es", { select: "official" })}`,
  value: it,
}));

export function getTranslatedCountryList(locale) {
  return locale === "en-US" ? enList : esList;
}
