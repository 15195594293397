import React from "react";
import { Helmet } from "react-helmet";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

// Assets
import Usability from "../../assets/svg/motor/01_motor_Usabilidad.svg";
import Promotions from "../../assets/svg/motor/02_motor_Promociones.svg";
import Extras from "../../assets/svg/motor/03_motor_Extras.svg";
import Policy from "../../assets/svg/motor/04_motor_Politica.svg";
import Chains from "../../assets/svg/motor/05_motor_Cadenas.svg";
import Comunication from "../../assets/svg/motor/06_motor_Comunicacion.svg";
import Custom from "../../assets/svg/motor/07_motor_Personalizacion.svg";
import Reports from "../../assets/svg/motor/08_motor_Informes.svg";
import Agencies from "../../assets/svg/motor/09_motor_Agencias.svg";
import Metas from "../../assets/svg/motor/10_motor_Metabuscadores.svg";
import Payment from "../../assets/svg/motor/11_motor_Sistemas de pago.svg";
import BreadCrumbs from "../../layout/breadcrumbs";

const messages = defineMessages({
  titleSEO: {
    id: "page.motor.titleSEO",
    description: "Witbooking booking Engine features",
    defaultMessage: "Funcionalidades motor de reservas Witbooking",
  },
  descriptionSEO: {
    id: "page.motor.descriptionSEO",
    description: "Features to optimize the direct sale of hotels, apartments, hostels and rural houses",
    defaultMessage: "Funcionalidades para optimizar la venta directa de hoteles, apartamentos, hostels y casas rurales",
  },
  keywordSEO:{
    id: "page.motor.keywordSEO",
    description: "Rate combination, meta search engines, hotel promotions, extras, mobile booking engine, package sales",
    defaultMessage: "Combinación de tarifas, metabuscadores, promociones hoteleras, extras, motor de reservas móvil, venta paquetes",
  },
  menuUsability: {
    id: "page.motor.menuUsability",
    description: "page menu option",
    defaultMessage: "Usabilidad",
  },
  menuPromotions: {
    id: "page.motor.menuPromotions",
    description: "page menu option",
    defaultMessage: "Promociones",
  },
  menuExtras: {
    id: "page.motor.menuExtras",
    description: "page menu option",
    defaultMessage: "Extras y paquetes",
  },
  menuPolicy: {
    id: "page.motor.menuPolicy",
    description: "page menu option",
    defaultMessage: "Política comercial",
  },
  menuChains: {
    id: "page.motor.menuChains",
    description: "page menu option",
    defaultMessage: "Cadenas",
  },
  menuComunication: {
    id: "page.motor.menuComunication",
    description: "page menu option",
    defaultMessage: "Comunicación",
  },
  menuCustom: {
    id: "page.motor.menuCustom",
    description: "page menu option",
    defaultMessage: "Personalización",
  },
  menuReports: {
    id: "page.motor.menuReports",
    description: "page menu option",
    defaultMessage: "Informes y analítica avanzada",
  },
  menuAgencies: {
    id: "page.motor.menuAgencies",
    description: "page menu option",
    defaultMessage: "Agencias, eventos y grupos",
  },
  menuMetas: {
    id: "page.motor.menuMetas",
    description: "page menu option",
    defaultMessage: "Metabuscadores",
  },
  menuPayment: {
    id: "page.motor.menuPayment",
    description: "page menu option",
    defaultMessage: "Sistemas de pago",
  },
  breadcrumb: {
    id: "page.motor.breadcrumb",
    description: "page breadcrumb",
    defaultMessage: "Motor de reservas",
  },
  pageTitle: {
    id: "page.motor.pageTitle",
    description: "page title",
    defaultMessage: "Motor de reservas",
  },
  pageIntro: {
    id: "page.motor.pageIntro",
    description: "page introduction",
    defaultMessage:
      "Las funcionalidades avanzadas a disposición del equipo de ecommerce del hotel junto con la facilidad de uso y la estética cuidada del motor, consiguen optimizar la conversión del canal directo.",
  },
  usabilityTitle: {
    id: "page.motor.usabilityTitle",
    description: "usability title",
    defaultMessage: "Usabilidad",
  },
  usabilityIntro: {
    id: "page.motor.usabilityIntro",
    description: "usability introduction",
    defaultMessage:
      "La facilidad vende. La usabilidad es la forma más eficiente de aumentar el ratio de conversión.",
  },
  usabilitySectionOneTitle: {
    id: "page.motor.usabilitySectionOneTitle",
    description: "usability section one title",
    defaultMessage: "Extra-rápido",
  },
  usabilitySectionOneText: {
    id: "page.motor.usabilitySectionOneText",
    description: "usability section one text",
    defaultMessage:
      "Responde siempre en 0,3 segundos gracias al hosting elástico montado sobre el cloud de Amazon Web Services (AWS).",
  },
  usabilitySectionTwoTitle: {
    id: "page.motor.usabilitySectionTwoTitle",
    description: "usability section two title",
    defaultMessage: "Visual",
  },
  usabilitySectionTwoText: {
    id: "page.motor.usabilitySectionTwoText",
    description: "usability section two text",
    defaultMessage:
      "Zona de contenido web con galería de fotos del hotel, mapa de situación, vídeos, vídeos Google 360º interactivos.",
  },
  usabilitySectionThreeTitle: {
    id: "page.motor.usabilitySectionThreeTitle",
    description: "usability section three title",
    defaultMessage: "Versión mobile",
  },
  usabilitySectionThreeText: {
    id: "page.motor.usabilitySectionThreeText",
    description: "usability section three text",
    defaultMessage: "Concebida en exclusiva para maximizar su conversión.",
  },
  usabilitySectionFourTitle: {
    id: "page.motor.usabilitySectionFourTitle",
    description: "usability section four title",
    defaultMessage: "Multi-idioma y divisa",
  },
  usabilitySectionFourText: {
    id: "page.motor.usabilitySectionFourText",
    description: "usability section four text",
    defaultMessage:
      "Traducido al español, inglés, francés, alemán, holandés, portugués, italiano, ruso, catalán, coreano, danés y euskera. Permite transformar los precios a la divisa local.",
  },
  usabilitySectionFiveTitle: {
    id: "page.motor.usabilitySectionFiveTitle",
    description: "usability section five title",
    defaultMessage: "Reservas múltiples",
  },
  usabilitySectionFiveText: {
    id: "page.motor.usabilitySectionFiveText",
    description: "usability section five text",
    defaultMessage:
      "Permite reservar varias habitaciones incluso con fechas distintas en un sólo proceso de compra.",
  },
  usabilitySectionSixTitle: {
    id: "page.motor.usabilitySectionSixTitle",
    description: "usability section six title",
    defaultMessage: "Reserva asistida",
  },
  usabilitySectionSixText: {
    id: "page.motor.usabilitySectionSixText",
    description: "usability section six text",
    defaultMessage:
      "En reservas con múltiples habitaciones, se guiará el proceso con un asistente paso a paso.",
  },
  usabilitySectionSevenTitle: {
    id: "page.motor.usabilitySectionSevenTitle",
    description: "usability section seven title",
    defaultMessage: "Alternativas de ocupación",
  },
  usabilitySectionSevenText: {
    id: "page.motor.usabilitySectionSevenText",
    description: "usability section seven text",
    defaultMessage:
      "WitBooker ofrece alternativas de alojamiento compatibles con la ocupación indicada, logrando así aumentar el ratio de disponibilidad.",
  },
  usabilitySectionEightTitle: {
    id: "page.motor.usabilitySectionEightTitle",
    description: "usability section eight title",
    defaultMessage: "Calendario interactivo",
  },
  usabilitySectionEightText: {
    id: "page.motor.usabilitySectionEightText",
    description: "usability section eight text",
    defaultMessage:
      "El huésped puede visualizar la disponibilidad y nivel de precios para aligerar el proceso de comparación y/o búsqueda de alternativas en caso de no disponibilidad.",
  },
  usabilitySectionNineTitle: {
    id: "page.motor.usabilitySectionNineTitle",
    description: "usability section nine title",
    defaultMessage: "Panel de control unificado",
  },
  usabilitySectionNineText: {
    id: "page.motor.usabilitySectionNineText",
    description: "usability section nine text",
    defaultMessage:
      "Poder gestionar todas las propiedades desde un único gestor con posibilidad de limitar permisos a los administradores en las distintas propiedades.",
  },
  usabilitySectionTenTitle: {
    id: "page.motor.usabilitySectionTenTitle",
    description: "usability section ten title",
    defaultMessage: "Carga y descarga de ficheros",
  },
  usabilitySectionTenText: {
    id: "page.motor.usabilitySectionTenText",
    description: "usability section ten text",
    defaultMessage:
      "El sistema permite descargar informes y ficheros de configuración en formatos editables en local ej (csv, excel) que tras ser editados podrán ser cargados de vuelta facilitando así una gestión cómoda y ágil.",
  },
  promotionsTitle: {
    id: "page.motor.promotionsTitle",
    description: "promotions title",
    defaultMessage: "Promociones",
  },
  promotionsIntro: {
    id: "page.motor.promotionsIntro",
    description: "promotions introduction",
    defaultMessage:
      "El mayor reclamo comercial son las promociones, poder contar con todos los recursos permite una política comercial más rica y persuasiva.",
  },
  promotionsSectionOneTitle: {
    id: "page.motor.promotionsSectionOneTitle",
    description: "promotions section one title",
    defaultMessage: "Tipologías",
  },
  promotionsSectionOneText: {
    id: "page.motor.promotionsSectionOneText",
    description: "promotions section one text",
    defaultMessage:
      "Descuento porcentual, importe fijo diario o por estancia.Días gratuitos (3x2, 5x4)Acumulables, no acumulables o mixtas.",
  },
  promotionsSectionTwoTitle: {
    id: "page.motor.promotionsSectionTwoTitle",
    description: "promotions section two title",
    defaultMessage: "Restricciones",
  },
  promotionsSectionTwoText: {
    id: "page.motor.promotionsSectionTwoText",
    description: "promotions section two text",
    defaultMessage:
      "Por fecha de reserva o fecha de estancia.Por longitud de estancia y/o antelación de contratación.Bloqueo de días (blackout).",
  },
  promotionsSectionThreeTitle: {
    id: "page.motor.promotionsSectionThreeTitle",
    description: "promotions section three title",
    defaultMessage: "Protegidas por código",
  },
  promotionsSectionThreeText: {
    id: "page.motor.promotionsSectionThreeText",
    description: "promotions section three text",
    defaultMessage:
      "Códigos promocionales de uso ilimitado.Códigos de un sólo uso.",
  },
  promotionsSectionFourTitle: {
    id: "page.motor.promotionsSectionFourTitle",
    description: "promotions section four title",
    defaultMessage: "Promociones dinámicas",
  },
  promotionsSectionFourText: {
    id: "page.motor.promotionsSectionFourText",
    description: "promotions section four text",
    defaultMessage:
      "Por país de conexión (mercado emisor).Por dispositivo (móvil/sobremesa)Por horario de conexión (laborable/festivo). ",
  },
  promotionsSectionFiveTitle: {
    id: "page.motor.promotionsSectionFiveTitle",
    description: "promotions section five title",
    defaultMessage: "Promociones de cadena",
  },
  promotionsSectionFiveText: {
    id: "page.motor.promotionsSectionFiveText",
    description: "promotions section five text",
    defaultMessage:
      "Se crean y se gestionan en el motor/agregador de la cadena, o los agregadores parciales (por destino, categoría…).",
  },
  extrasTitle: {
    id: "page.motor.extrasTitle",
    description: "extras title",
    defaultMessage: "Extras",
  },
  extrasIntro: {
    id: "page.motor.extrasIntro",
    description: "extras introduction",
    defaultMessage:
      "Los extras y paquetes no sólo son una buena forma de aumentar el precio medio, además son la motivación de muchos huéspedes que justifica el viaje.",
  },
  extrasSectionOneTitle: {
    id: "page.motor.extrasSectionOneTitle",
    description: "extras section one title",
    defaultMessage: "Extras por tipo de precio",
  },
  extrasSectionOneText: {
    id: "page.motor.extrasSectionOneText",
    description: "extras section one text",
    defaultMessage:
      "Por persona (ej. Albornoz)Por persona / día (ej. Entrada al SPA)Por habitación (ej. Botella de vino de bienvenida)Por habitación / día (ej. Cesta de fruta diaria)Por unidad (ej. Sesión de masaje de 45 min).",
  },
  extrasSectionTwoTitle: {
    id: "page.motor.extrasSectionTwoTitle",
    description: "extras section two title",
    defaultMessage: "Extras con información adicional",
  },
  extrasSectionTwoText: {
    id: "page.motor.extrasSectionTwoText",
    description: "extras section two text",
    defaultMessage:
      "Selección entre opciones cerradas (ej. menú carne, pasta o vegetariano).Información adicional (ej. número de vuelo para el transfer).Selección de día/s de disfrute (ej. forfait ski). Selección de día/s y horario/s de disfrute (ej. masaje, green-fee golf).",
  },
  extrasSectionThreeTitle: {
    id: "page.motor.extrasSectionThreeTitle",
    description: "extras section three title",
    defaultMessage: "Extras gratuitos",
  },
  extrasSectionThreeText: {
    id: "page.motor.extrasSectionThreeText",
    description: "extras section three text",
    defaultMessage:
      "Protegidos por código (ej. Cocktail bienvenida para campaña en instagram).Abiertos (ej. Late checkout para clientes directos).",
  },
  extrasSectionFourTitle: {
    id: "page.motor.extrasSectionFourTitle",
    description: "extras section four title",
    defaultMessage: "Restricciones de extras",
  },
  extrasSectionFourText: {
    id: "page.motor.extrasSectionFourText",
    description: "extras section four text",
    defaultMessage:
      "Por fecha de reserva o fecha de estancia.Por longitud de estancia y/o antelación de contratación.Bloqueo de días (blackout).Ligados a un código promocional.",
  },
  extrasSectionFiveTitle: {
    id: "page.motor.extrasSectionFiveTitle",
    description: "extras section five title",
    defaultMessage: "Paquetes",
  },
  extrasSectionFiveText: {
    id: "page.motor.extrasSectionFiveText",
    description: "extras section five text",
    defaultMessage:
      "Se pueden crear paquetes vacacionales combinando un tipo de alojamiento con una serie de servicios extra con las siguientes características:Con ficha personalizada del paquete con descripción, ítems, fotos y videos propios.Selector de tipos de habitación compatibles con comparativa de precio para fomentar el upgrading.Permite referenciar precio del paquete a una habitación sumando un sobrecoste para maximizar los ingresos (revenue management).Permite referenciar la disponibilidad a un tipo de alojamiento para evitar la sobreventa (overbooking).",
  },
  policyTitle: {
    id: "page.motor.policyTitle",
    description: "policy title",
    defaultMessage: "Política comercial",
  },
  policyIntro: {
    id: "page.motor.policyIntro",
    description: "policy introduction",
    defaultMessage:
      "Una política comercial flexible y orientada al cliente permite ofrecer a los huéspedes ventajas de reserva directa para potenciar el canal web respecto a los intermediarios.",
  },
  policySectionOneTitle: {
    id: "page.motor.policySectionOneTitle",
    description: "policy section one title",
    defaultMessage: "Inventario inteligente",
  },
  policySectionOneText: {
    id: "page.motor.policySectionOneText",
    description: "policy section one text",
    defaultMessage:
      "Múltiples configuraciones por habitación según ocupación, pensión y/o condiciones. Podrán compartir cupo y referenciar su tarifa para simplificar su mantenimiento.",
  },
  policySectionTwoTitle: {
    id: "page.motor.policySectionTwoTitle",
    description: "policy section two title",
    defaultMessage: "Tarifas combinadas",
  },
  policySectionTwoText: {
    id: "page.motor.policySectionTwoText",
    description: "policy section two text",
    defaultMessage:
      "Combina tarifas restringidas para ofrecer el mejor precio y evitar disparidades con las OTAs, especialmente en metabuscadores.",
  },
  policySectionThreeTitle: {
    id: "page.motor.policySectionThreeTitle",
    description: "policy section three title",
    defaultMessage: "Cancelación avanzada",
  },
  policySectionThreeText: {
    id: "page.motor.policySectionThreeText",
    description: "policy section three text",
    defaultMessage:
      "Personaliza diferentes políticas de cancelación según rango de fechas, penalizando por porcentaje, importe fijo o valor de la primera noche.",
  },
  policySectionFourTitle: {
    id: "page.motor.policySectionFourTitle",
    description: "policy section four title",
    defaultMessage: "Modificación de reservas",
  },
  policySectionFourText: {
    id: "page.motor.policySectionFourText",
    description: "policy section four text",
    defaultMessage:
      "El huésped podrá modificar online fechas, tipo habitación y/o extras si la tarifa lo permite de manera autónoma.",
  },
  policySectionFiveTitle: {
    id: "page.motor.policySectionFiveTitle",
    description: "policy section five title",
    defaultMessage: "Sistema de fidelización",
  },
  policySectionFiveText: {
    id: "page.motor.policySectionFiveText",
    description: "policy section five text",
    defaultMessage:
      "Para poder conocer a nuestros clientes, generar una base de datos, ofrecerles productos & tarifas personalizadas y fomentar además la venta cruzada en los establecimientos ver más.",
  },
  policySectionSixTitle: {
    id: "page.motor.policySectionSixTitle",
    description: "policy section six title",
    defaultMessage: "Cotizador (pre-reservas)",
  },
  policySectionSixText: {
    id: "page.motor.policySectionSixText",
    description: "policy section six text",
    defaultMessage:
      "Permite enviar pre-reservas mediante un enlace con descuentos especiales para facilitar las reservas a los clientes que contactan por email y teléfono, pudiendo (opcionalmente) bloquear cupo y tarifa durante unas horas.",
  },
  policySectionSevenTitle: {
    id: "page.motor.policySectionSevenTitle",
    description: "policy section five title",
    defaultMessage: "Cheques regalo y tienda",
  },
  policySectionSevenText: {
    id: "page.motor.policySectionSevenText",
    description: "policy section seven text",
    defaultMessage:
      "Para poder vender cheques regalo canjeables online (sin necesidad de llamar) en nuestras propiedades, así como productos y servicios sin alojamiento.",
  },
  policySectionSevenBtn: {
    id: "page.motor.policySectionSevenBtn",
    description: "policy section seven btn",
    defaultMessage: "Tienda online",
  },
  chainsTitle: {
    id: "page.motor.chainsTitle",
    description: "chains title",
    defaultMessage: "Cadenas",
  },
  chainsIntro: {
    id: "page.motor.chainsIntro",
    description: "chains introduction",
    defaultMessage:
      "Witbooking ofrece las siguientes funcionalidades específicas para una óptima comercialización de grupos hoteleros.",
  },
  chainsSectionOneTitle: {
    id: "page.motor.chainsSectionOneTitle",
    description: "chains section one title",
    defaultMessage: "Central de reservas con mapa y filtros",
  },
  chainsSectionOneText: {
    id: "page.motor.chainsSectionOneText",
    description: "chains section one text",
    defaultMessage:
      "Interfaz para mostrar la oferta de todos los hoteles de la cadena con un mapa interactivo y permitiendo ordenar y filtrar opciones por destino, por precio y/o atributos.",
  },
  chainsSectionTwoTitle: {
    id: "page.motor.chainsSectionTwoTitle",
    description: "chains section two title",
    defaultMessage: "Informes agregados",
  },
  chainsSectionTwoText: {
    id: "page.motor.chainsSectionTwoText",
    description: "chains section two text",
    defaultMessage:
      "Para poder evaluar el rendimiento de nuestros establecimientos sin perder tiempo agrupando datos en una hoja de cálculo.",
  },
  chainsSectionThreeTitle: {
    id: "page.motor.chainsSectionThreeTitle",
    description: "chains section three title",
    defaultMessage: "Promociones multi-propiedad",
  },
  chainsSectionThreeText: {
    id: "page.motor.chainsSectionThreeText",
    description: "chains section three text",
    defaultMessage:
      "Que permiten hacer ofertas en todos o varios de los establecimientos sin necesidad de ir duplicando promociones en cada establecimiento.",
  },
  chainsSectionFourTitle: {
    id: "page.motor.chainsSectionFourTitle",
    description: "chains section four title",
    defaultMessage: "Venta cruzada",
  },
  chainsSectionFourText: {
    id: "page.motor.chainsSectionFourText",
    description: "chains section four text",
    defaultMessage:
      "Deberá permitirte redirigir las peticiones de un establecimiento a otro/s en caso de no disponibilidad.",
  },
  chainsSectionFiveTitle: {
    id: "page.motor.chainsSectionFiveTitle",
    description: "chains section five title",
    defaultMessage: "Tienda online",
  },
  chainsSectionFiveText: {
    id: "page.motor.chainsSectionFiveText",
    description: "chains section five text",
    defaultMessage:
      "Para poder vender cheques regalo canjeables online (sin necesidad de llamar) en nuestras propiedades, así como productos y servicios sin alojamiento.",
  },
  chainsSectionFiveBtn: {
    id: "page.motor.chainsSectionFiveBtn",
    description: "chains section five btn",
    defaultMessage: "Tienda online",
  },
  chainsSectionSixTitle: {
    id: "page.motor.chainsSectionSixTitle",
    description: "chains section six title",
    defaultMessage: "Sistema de fidelización",
  },
  chainsSectionSixText: {
    id: "page.motor.chainsSectionSixText",
    description: "chains section six text",
    defaultMessage:
      "Para poder conocer a nuestros clientes, generar una base de datos, ofrecerles productos & tarifas personalizadas y fomentar además la venta cruzada en los establecimientos.",
  },
  chainsSectionSixBtn: {
    id: "page.motor.chainsSectionSixBtn",
    description: "chains section six btn",
    defaultMessage: "Sistema fidelización",
  },
  chainsSectionSevenTitle: {
    id: "page.motor.chainsSectionSevenTitle",
    description: "chains section seven title",
    defaultMessage: "Panel de control unificado con permisos de usuarios",
  },
  chainsSectionSevenText: {
    id: "page.motor.chainsSectionSevenText",
    description: "chains section seven text",
    defaultMessage:
      "Poder gestionar todas las propiedades desde un único gestor con posibilidad de limitar permisos a los administradores (propiedad, revenue, recepción, administración...) en las distintas propiedades.",
  },
  comunicationTitle: {
    id: "page.motor.comunicationTitle",
    description: "comunication title",
    defaultMessage: "Comunicación",
  },
  comunicationIntro: {
    id: "page.motor.comunicationIntro",
    description: "comunication introduction",
    defaultMessage:
      "Una comunicación con mensajes claros, contextuales y respetando la imagen corporativa ayudan a mejorar significativamente la conversión del canal propio.",
  },
  comunicationSectionOneTitle: {
    id: "page.motor.comunicationSectionOneTitle",
    description: "comunication section one title",
    defaultMessage: "Apartado de ventajas y destacados",
  },
  comunicationSectionOneText: {
    id: "page.motor.comunicationSectionOneText",
    description: "comunication section one text",
    defaultMessage:
      "Sección visible durante todo el proceso de reserva que destaca todas las ventajas de la reserva directa (regalos, promociones, beneficios exclusivos)",
  },
  comunicationSectionTwoTitle: {
    id: "page.motor.comunicationSectionTwoTitle",
    description: "comunication section two title",
    defaultMessage: "Mensajes contextuales",
  },
  comunicationSectionTwoText: {
    id: "page.motor.comunicationSectionTwoText",
    description: "comunication section two text",
    defaultMessage:
      "Mensajes localizados en los distintos pasos / secciones del proceso de reserva para informar en el momento adecuado.",
  },
  comunicationSectionThreeTitle: {
    id: "page.motor.comunicationSectionThreeTitle",
    description: "comunication section three title",
    defaultMessage: "Email de confirmación",
  },
  comunicationSectionThreeText: {
    id: "page.motor.comunicationSectionThreeText",
    description: "comunication section three text",
    defaultMessage:
      "Se envían automáticamente al huésped con control de recepción y lectura. Incluye enlace de modificación/cancelación de la reserva en caso de estar habilitadas estas opciones para la tarifa.",
  },
  comunicationSectionFourTitle: {
    id: "page.motor.comunicationSectionFourTitle",
    description: "comunication section four title",
    defaultMessage: "Email pre-estancia y post-estancia",
  },
  comunicationSectionFourText: {
    id: "page.motor.comunicationSectionFourText",
    description: "comunication section four text",
    defaultMessage:
      "Envio previo a su llegada para el upselling y tras su estancia para monitorizar la calidad.",
  },
  comunicationSectionFiveTitle: {
    id: "page.motor.comunicationSectionFiveTitle",
    description: "comunication section five title",
    defaultMessage: "Emails con diseño personalizado",
  },
  comunicationSectionFiveText: {
    id: "page.motor.comunicationSectionFiveText",
    description: "comunication section five text",
    defaultMessage:
      "Permite la personalización completa del aspecto del email (HTML) y condicionar su contenido a cada tipo de habitación, tipo de tarifa o un código promocional.",
  },
  comunicationSectionSixTitle: {
    id: "page.motor.comunicationSectionSixTitle",
    description: "comunication section six title",
    defaultMessage: "Mensaje de reserva fallida",
  },
  comunicationSectionSixText: {
    id: "page.motor.comunicationSectionSixText",
    description: "comunication section six text",
    defaultMessage:
      "Envío automático de un email al departamento de reservas cuando ha fallado el proceso de pago, permitiendo recuperar la reservas mediante el contacto directo con el huésped al haber registrado los datos de contacto (email / teléfono).",
  },
  customTitle: {
    id: "page.motor.customTitle",
    description: "custom title",
    defaultMessage: "Personalización",
  },
  customIntro: {
    id: "page.motor.customIntro",
    description: "custom introduction",
    defaultMessage:
      "Una buena personalización del motor de reservas hace que el usuario no perciba un cambio de contexto entre la web y el motor de reservas.",
  },
  customSectionOneTitle: {
    id: "page.motor.customSectionOneTitle",
    description: "custom section one title",
    defaultMessage: "Integración en la web",
  },
  customSectionOneText: {
    id: "page.motor.customSectionOneText",
    description: "custom section one text",
    defaultMessage:
      "El motor puede integrarse en la web del cliente para ofrecer la máxima continuidad en el proceso de reserva, haciendo que la frontera web-motor sea imperceptible.",
  },
  customSectionTwoTitle: {
    id: "page.motor.customSectionTwoTitle",
    description: "custom section two title",
    defaultMessage: "Dominio propio securizado",
  },
  customSectionTwoText: {
    id: "page.motor.customSectionTwoText",
    description: "custom section two text",
    defaultMessage:
      "El motor se sirve desde el dominio del cliente (reservations.hotelX.com) para reforzar la confianza del huésped y mejorar el ratio de conversión.",
  },
  customSectionThreeTitle: {
    id: "page.motor.customSectionThreeTitle",
    description: "custom section three title",
    defaultMessage: "Mensajes contextuales personalizados",
  },
  customSectionThreeText: {
    id: "page.motor.customSectionThreeText",
    description: "custom section three text",
    defaultMessage:
      "Facilita insertar anuncios y banners en HTML con el diseño del hotel y diferentes tipos de mensaje para informar al cliente.",
  },
  customSectionFourTitle: {
    id: "page.motor.customSectionFourTitle",
    description: "custom section four title",
    defaultMessage: "Filtros",
  },
  customSectionFourText: {
    id: "page.motor.customSectionFourText",
    description: "custom section four text",
    defaultMessage:
      "Para una óptima conversión los sistemas de reservas deben permitir filtrar la información que ofrecen, evitando mostrar muchos establecimientos, tipos de habitaciones  y/o tarifas/regímenes de una sola vez se logran mejores resultados.",
  },
  customSectionFiveTitle: {
    id: "page.motor.customSectionFiveTitle",
    description: "custom section five title",
    defaultMessage: "Vista lista o tabla",
  },
  customSectionFiveText: {
    id: "page.motor.customSectionFiveText",
    description: "custom section five text",
    defaultMessage:
      "Mostrar los resultados de una búsqueda de alojamiento en forma de lista o tabla permite mejorar el aspecto de los resultados según su cantidad.",
  },
  customSectionSixTitle: {
    id: "page.motor.customSectionSixTitle",
    description: "custom section six title",
    defaultMessage: "Acceso API",
  },
  customSectionSixText: {
    id: "page.motor.customSectionSixText",
    description: "custom section six text",
    defaultMessage:
      "El motor provee a los hoteleros un acceso programático para que se pueda enriquecer la web con datos propios del sistema de reservas (ej. precios, calendarios de disponibilidad).",
  },
  reportsTitle: {
    id: "page.motor.reportsTitle",
    description: "reports title",
    defaultMessage: "Informes y analítica avanzada",
  },
  reportsIntro: {
    id: "page.motor.reportsIntro",
    description: "reports introduction",
    defaultMessage:
      "Un buen sistema de reservas proporciona información de gran valor para elaborar una estrategia de producto y de revenue management adecuada.",
  },
  reportsSectionOneTitle: {
    id: "page.motor.reportsSectionOneTitle",
    description: "reports section one title",
    defaultMessage: "Dashboard",
  },
  reportsSectionOneText: {
    id: "page.motor.reportsSectionOneText",
    description: "reports section one text",
    defaultMessage:
      "En el backoffice se muestran diferentes indicadores para controlar de forma rápida la actividad del motor para un control diario eficiente.",
  },
  reportsSectionTwoTitle: {
    id: "page.motor.reportsSectionTwoTitle",
    description: "reports section two title",
    defaultMessage: "Avisos proactivos",
  },
  reportsSectionTwoText: {
    id: "page.motor.reportsSectionTwoText",
    description: "reports section two text",
    defaultMessage:
      "Cuando una parte del sistema no está optimizada el sistema avisa al administrador invitando a su corrección (ej. poco horizonte de disponibilidad cargada).",
  },
  reportsSectionThreeTitle: {
    id: "page.motor.reportsSectionThreeTitle",
    description: "reports section three title",
    defaultMessage: "Informes avanzados",
  },
  reportsSectionThreeText: {
    id: "page.motor.reportsSectionThreeText",
    description: "reports section three text",
    defaultMessage:
      "Witbooking genera todos los informes en tiempo real para que sus clientes puedan analizar la actividad de venta online, pudiendo descargarlos para realizar análisis en profundidad.",
  },
  reportsSectionFourTitle: {
    id: "page.motor.reportsSectionFourTitle",
    description: "reports section four title",
    defaultMessage: "Análisis y predicción de la demanda (Big data)",
  },
  reportsSectionFourText: {
    id: "page.motor.reportsSectionFourText",
    description: "reports section four text",
    defaultMessage:
      "El motor capta una enorme cantidad de datos de la actividad registrada para facilitar el conocimiento y predicción del comportamiento de la demanda. Capta información exhaustiva de los usuarios (ej. localización, dispositivo) y su comportamiento en el proceso de reserva (ej pasos/subpasos completados, número-duración de las conexiones).",
  },
  reportsSectionFiveTitle: {
    id: "page.motor.reportsSectionFiveTitle",
    description: "reports section five title",
    defaultMessage: "Descarga, data layer y API",
  },
  reportsSectionFiveText: {
    id: "page.motor.reportsSectionFiveText",
    description: "reports section five text",
    defaultMessage:
      "Los datos podrán descargarse mediante ficheros csv o bien trasladarse al sistema de analítica del cliente (data layer). La información también está disponible para sistemas externos (ej. RMS) mediante conexión API.",
  },
  agenciesTitle: {
    id: "page.motor.agenciesTitle",
    description: "agencies title",
    defaultMessage: "Agencias, eventos y grupos",
  },
  agenciesIntro: {
    id: "page.motor.agenciesIntro",
    description: "agencies introduction",
    defaultMessage:
      "Las agencias de viajes, departamentos de reservas, call-centers y organizadores de eventos requieren de funcionalidades adicionales para gestionar y controlar la actividad.",
  },
  agenciesSectionOneTitle: {
    id: "page.motor.agenciesSectionOneTitle",
    description: "agencies section one title",
    defaultMessage: "Inventario específico",
  },
  agenciesSectionOneText: {
    id: "page.motor.agenciesSectionOneText",
    description: "agencies section one text",
    defaultMessage:
      "Permite limitar determinadas opciones de alojamiento y/o tarifas a los distintos colectivos que se definan.",
  },
  agenciesSectionTwoTitle: {
    id: "page.motor.agenciesSectionTwoTitle",
    description: "agencies section two title",
    defaultMessage: "Cotizador",
  },
  agenciesSectionTwoText: {
    id: "page.motor.agenciesSectionTwoText",
    description: "agencies section two text",
    defaultMessage:
      "Facilita la generación de pre-reservas (con o sin bloqueo de cupo y precio) para que el cliente final sólo tenga que introducir sus datos de pago y confirmar la transacción.",
  },
  agenciesSectionThreeTitle: {
    id: "page.motor.agenciesSectionThreeTitle",
    description: "agencies section three title",
    defaultMessage: "Trazabilidad",
  },
  agenciesSectionThreeText: {
    id: "page.motor.agenciesSectionThreeText",
    description: "agencies section three text",
    defaultMessage:
      "Permite controlar la actividad originada por cada ente: comerciales, recepcionistas, teleoperadores, portales, agencias de viaje, etc.",
  },
  agenciesSectionFourTitle: {
    id: "page.motor.agenciesSectionFourTitle",
    description: "agencies section four title",
    defaultMessage: "Informes automáticos",
  },
  agenciesSectionFourText: {
    id: "page.motor.agenciesSectionFourText",
    description: "agencies section four text",
    defaultMessage:
      "Cada agente o agencia podrá obtener su informe de actividad bajo demanda de forma desasistida en su área privada, evitando así al hotelero tener que distribuir esta información recurrentemente.",
  },
  agenciesSectionFiveTitle: {
    id: "page.motor.agenciesSectionFiveTitle",
    description: "agencies section five title",
    defaultMessage: "Eventos",
  },
  agenciesSectionFiveText: {
    id: "page.motor.agenciesSectionFiveText",
    description: "agencies section five text",
    defaultMessage:
      "Habilita una zona privada personalizada donde los miembros de un colectivo (bodas, congresos) podrán hacer sus reservas individuales con cupos y tarifas personalizadas.",
  },
  metasTitle: {
    id: "page.motor.metasTitle",
    description: "metas title",
    defaultMessage: "Metabuscadores",
  },
  metasIntro: {
    id: "page.motor.metasIntro",
    description: "metas introduction",
    defaultMessage:
      "Witbooking ofrece conectividad directa con los principales metabuscadores sin coste adicional ni comisiones por reserva.",
  },
  metasSectionOneTitle: {
    id: "page.motor.metasSectionOneTitle",
    description: "metas section one title",
    defaultMessage: "Conectividad directa con los grandes",
  },
  metasSectionOneText: {
    id: "page.motor.metasSectionOneText",
    description: "metas section one text",
    defaultMessage:
      "Google (Google Hotel Ads), Trivago (Trivago Direct Connect) y TripAdvisor (TripConnect).",
  },
  metasSectionTwoTitle: {
    id: "page.motor.metasSectionTwoTitle",
    description: "metas section two title",
    defaultMessage: "Conectividad con “meta-managers”",
  },
  metasSectionTwoText: {
    id: "page.motor.metasSectionTwoText",
    description: "metas section two text",
    defaultMessage:
      "Meta IO (Wihp), MyHotelShop, DerbySoft para aquellos que quieran automatizar la gestión de  sus pujas y/o gestionar de forma unificada su presupuesto.",
  },
  metasSectionThreeTitle: {
    id: "page.motor.metasSectionThreeTitle",
    description: "metas section three title",
    defaultMessage: "Latencia mínima",
  },
  metasSectionThreeText: {
    id: "page.motor.metasSectionThreeText",
    description: "metas section three text",
    defaultMessage:
      "La actualización de precios y disponibilidad se realiza con alta frecuencia para garantizar la máxima integridad de los datos y así evitar campañas fallidas.",
  },
  metasSectionFourTitle: {
    id: "page.motor.metasSectionFourTitle",
    description: "metas section four title",
    defaultMessage: "Sin coste extra ni comisiones",
  },
  metasSectionFourText: {
    id: "page.motor.metasSectionFourText",
    description: "metas section four text",
    defaultMessage:
      "Witbooking es un partner cuya misión es proveer tecnología para la desintermediación hotelera, por tanto comisionar por las reservas en los metabuscadores sería incoherente.",
  },
  paymentTitle: {
    id: "page.motor.paymentTitle",
    description: "payment title",
    defaultMessage: "Sistemas de pago",
  },
  paymentIntro: {
    id: "page.motor.paymentIntro",
    description: "payment introduction",
    defaultMessage:
      "Una de las formas más eficientes de aumentar el ratio de conversión de un sistema de reservas es adecuar las formas de pago a las necesidades y costumbres de los huéspedes.",
  },
  paymentSectionOneTitle: {
    id: "page.motor.paymentSectionOneTitle",
    description: "payment section one title",
    defaultMessage: "Integraciones",
  },
  paymentSectionOneText: {
    id: "page.motor.paymentSectionOneText",
    description: "payment section one text",
    defaultMessage:
      "Servired-Redsys, Paypal, Stripe, Pay No Pain, Comercia Global Payments / Addon Payments (LaCaixa), PayComet (Banco Sabadell), Universal Pay, PCI Proxy (Datatrans), Bizum.",
  },
  paymentSectionTwoTitle: {
    id: "page.motor.paymentSectionTwoTitle",
    description: "payment section two title",
    defaultMessage: "Formas de pago soportadas",
  },
  paymentSectionTwoText: {
    id: "page.motor.paymentSectionTwoText",
    description: "payment section two text",
    defaultMessage:
      "Pago online mediante redirección a la pasarela de pago. Pago online integrado en el sistema de reservas (friction-less). Pago diferido contra una tarjeta de crédito capturada en el momento de la reserva (ej. cobro de no show). Pago mediante transferencia bancaria. Envío de un enlace de pago con límite temporal. Reserva sin garantía (para épocas de baja demanda).",
  },
  paymentSectionThreeTitle: {
    id: "page.motor.paymentSectionThreeTitle",
    description: "payment section three title",
    defaultMessage: "Cumplimiento de normativas PCI-DSS y Psd2",
  },
  paymentSectionThreeText: {
    id: "page.motor.paymentSectionThreeText",
    description: "payment section three text",
    defaultMessage:
      "Cumplen tanto las normativas establecidas por las marcas (VISA, MasterCard, Amex) en su estándar PCI-DSS como la normativa europea Psd2 para los pagos digitales.",
  },
  paymentSectionFourTitle: {
    id: "page.motor.paymentSectionFourTitle",
    description: "payment section four title",
    defaultMessage: "Descarga de tarjetas de crédito",
  },
  paymentSectionFourText: {
    id: "page.motor.paymentSectionFourText",
    description: "payment section four text",
    defaultMessage:
      "Permite el envío seguro de los datos de la tarjeta de crédito al channel manager o PMS cumpliendo normativa PCI-DSS para su procesamiento centralizado.",
  },
  paymentSectionFiveTitle: {
    id: "page.motor.paymentSectionFiveTitle",
    description: "payment section five title",
    defaultMessage: "Módulo Smart Payments",
  },
  paymentSectionFiveText: {
    id: "page.motor.paymentSectionFiveText",
    description: "payment section five text",
    defaultMessage:
      "Este módulo permite elevar la conversión y reducir el fraude mediante funcionalidades avanzadas en el ámbito de los cobros.",
  },
  paymentSectionFiveBtn: {
    id: "page.motor.paymentSectionFiveBtn",
    description: "payment section five btn",
    defaultMessage: "Pagos Smart",
  },
});

export default function Motor() {
  const intl = useIntl();

  return (
    <>
      <Helmet >
        <title>{intl.formatMessage(messages.titleSEO)}</title>
        <meta name="title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta name="description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta name="keywords" content={intl.formatMessage(messages.keywordSEO)}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://metatags.io/"/>
        <meta property="og:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="og:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="og:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://metatags.io/"/>
        <meta property="twitter:title" content={intl.formatMessage(messages.titleSEO)}/>
        <meta property="twitter:description" content={intl.formatMessage(messages.descriptionSEO)}/>
        <meta property="twitter:image" content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"/>
      </Helmet>

      <BreadCrumbs
        breadcrumbText={intl.formatMessage(messages.breadcrumb)}
        pageTitle={intl.formatMessage(messages.pageTitle)}
        pageIntro={intl.formatMessage(messages.pageIntro)}
        classHeader="var-menu-space"
      />
      <div className="inlinks-menu-detector">
        <aside className="inlinks-menu">
          <div className="wrap-inlinks-menu">
            <ul className="inlinks-menu-list">
              <li
                className="inlinks-menu-element active js-scrollto"
                data-scroll_to="#usabilidad"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuUsability} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#promociones"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuPromotions} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#extras-paquetes"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuExtras} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#politica-comercial"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuPolicy} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#cadenas"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuChains} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#comunicacion"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuComunication} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#personalizacion"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuCustom} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#informes-y-analitica-avanzada"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuReports} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#agencias-eventos-grupos"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuAgencies} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#metabuscadores"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuMetas} />
                  </p>
                </div>
              </li>
              <li
                className="inlinks-menu-element js-scrollto"
                data-scroll_to="#sistemas-de-pago"
                data-scroll_spacer="80"
                role="button"
                tabIndex="0"
              >
                <div className="wrap-inlinks-element">
                  <p className="inlinks-element-text">
                    <FormattedMessage {...messages.menuPayment} />
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </aside>
      </div>

      <section
        id="usabilidad"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Usability}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.usabilityTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilityIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionFiveText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionSixTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionSixText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionSevenTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionSevenText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionEightTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionEightText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionNineTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionNineText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.usabilitySectionTenTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.usabilitySectionTenText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="promociones"
        className="gallery-details js-point-detector var-info-right"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Promotions}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.promotionsTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.promotionsIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.promotionsSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.promotionsSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.promotionsSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.promotionsSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.promotionsSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.promotionsSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.promotionsSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.promotionsSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.promotionsSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.promotionsSectionFiveText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="extras-paquetes"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Extras}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.extrasTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.extrasIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.extrasSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.extrasSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.extrasSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.extrasSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.extrasSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.extrasSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.extrasSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.extrasSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.extrasSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.extrasSectionFiveText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="politica-comercial"
        className="gallery-details js-point-detector var-info-right"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Policy}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.policyTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policyIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.policySectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policySectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.policySectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policySectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.policySectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policySectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.policySectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policySectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.policySectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policySectionFiveText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.policySectionSixTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policySectionSixText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.policySectionSevenTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.policySectionSevenText} />
                </p>
                <div className="details-content-btn btn-corp">
                  <a href="/add-on#tienda-online" className="btn-corp-text">
                    <FormattedMessage {...messages.policySectionSevenBtn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="cadenas"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Chains}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.chainsTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.chainsSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.chainsSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.chainsSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.chainsSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.chainsSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsSectionFiveText} />
                </p>
                <div className="details-content-btn btn-corp">
                  <a href="/add-on#tienda-online" className="btn-corp-text">
                    <FormattedMessage {...messages.chainsSectionFiveBtn} />
                  </a>
                </div>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.chainsSectionSixTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsSectionSixText} />
                </p>
                <div className="details-content-btn btn-corp">
                  <a
                    href="/add-on#sistema-fidelizacion"
                    className="btn-corp-text"
                  >
                    <FormattedMessage {...messages.chainsSectionSixBtn} />
                  </a>
                </div>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.chainsSectionSevenTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.chainsSectionSevenText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="comunicacion"
        className="gallery-details js-point-detector var-info-right"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Comunication}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.comunicationTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.comunicationIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.comunicationSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.comunicationSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.comunicationSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.comunicationSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage
                    {...messages.comunicationSectionThreeTitle}
                  />
                </p>
                <p className="details-content-text">
                  <FormattedMessage
                    {...messages.comunicationSectionThreeText}
                  />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage
                    {...messages.comunicationSectionFourTitle}
                  />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.comunicationSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage
                    {...messages.comunicationSectionFiveTitle}
                  />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.comunicationSectionFiveText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.comunicationSectionSixTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.comunicationSectionSixText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="personalizacion"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Custom}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.customTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.customIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.customSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.customSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.customSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.customSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.customSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.customSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.customSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.customSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.customSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.customSectionFiveText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.customSectionSixTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.customSectionSixText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="informes-y-analitica-avanzada"
        className="gallery-details js-point-detector var-info-right"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Reports}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.reportsTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.reportsIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.reportsSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.reportsSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.reportsSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.reportsSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.reportsSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.reportsSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.reportsSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.reportsSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.reportsSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.reportsSectionFiveText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="agencias-eventos-grupos"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Agencies}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.agenciesTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.agenciesIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.agenciesSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.agenciesSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.agenciesSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.agenciesSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.agenciesSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.agenciesSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.agenciesSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.agenciesSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.agenciesSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.agenciesSectionFiveText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="metabuscadores"
        className="gallery-details js-point-detector var-info-right"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Metas}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.metasTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.metasIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.metasSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.metasSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.metasSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.metasSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.metasSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.metasSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.metasSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.metasSectionFourText} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="sistemas-de-pago"
        className="gallery-details js-point-detector var-info-left"
      >
        <div className="wrap-gallery-details">
          <div className="gallery-details-multimedia">
            <div className="wrap-details-multimedia">
              <div className="details-multimedia-box">
                <img
                  src=""
                  alt=""
                  className="details-multimedia-graphic"
                  data-lazyimg={Payment}
                />
              </div>
            </div>
          </div>
          <div className="gallery-details-content">
            <div className="wrap-details-content">
              <div className="details-content-intro">
                <h2 className="details-content-title">
                  <FormattedMessage {...messages.paymentTitle} />
                </h2>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentIntro} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionOneTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionOneText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionTwoTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionTwoText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionThreeTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionThreeText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionFourTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionFourText} />
                </p>
              </div>
              <div className="details-content-point">
                <p className="details-content-subtitle">
                  <FormattedMessage {...messages.paymentSectionFiveTitle} />
                </p>
                <p className="details-content-text">
                  <FormattedMessage {...messages.paymentSectionFiveText} />
                </p>
                <div className="details-content-btn btn-corp">
                  <a href="/add-on#pagos-smart" className="btn-corp-text">
                    <FormattedMessage {...messages.paymentSectionFiveBtn} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
